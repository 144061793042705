<template>
  <img class="" v-if="src" :src="src" :alt="alt" :uk-img="'target: #' + target">
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Img",
  props: {
    src: String,
    alt: String,
    target: String
  }
}
</script>

<style scoped>

</style>