<template>
    <div class=" w-[504px] bg-[#fff] z-[9999999] h-full right-0 top-0">
        <div class="bg-[#614CA7] flex flex-row justify-between items-center
                            py-[20px]  pl-[37px] pr-[36.5px]">
            <div class="flex flex-row items-center text-[#fff]">
                <div>
                    <img src="@/assets/korzinka-icon.svg" alt="">
                </div>
                <div class="ml-[15px]">
                    {{ meta_data.length }} {{__("pc")}}
                </div>
            </div>
            <div class="cursor-pointer">
                <img src="@/assets/close-icon.svg" class="uk-offcanvas-close" alt="close">
            </div>
        </div>
        <div class="pl-[37px] pr-[36.5px] pt-[34px]">
            <div class="flex flex-col">
                <div>
                    <div class="flex flex-row items-center justify-between pb-[19px] border-b border-[#E7E2F9]" v-for="item in meta_data" :key="item.id" style="margin-bottom: 15px;"
                    >
                        <div class="py-[18px] rounded-[7px] px-[30px] max-w-[143px] max-h-[145px]">
                            <img src="@/assets/meta_file.png" alt="file" class="max-w-[83px] max-h-[110px]">
                        </div>
                        <div class="grid grid-cols-4 gap-2">
                            <div class="col-span-3 text-[16px] pl-3 text-[#000] font-medium max-w-[263px]">
                                <p class="m-0">{{__("name")}}: <span class="font-normal">{{ item.name }}</span></p>
                                <p class="m-0">{{__("nomenclature")}}: <span class="font-normal">{{ item.nomenclature}}</span></p>
                                <p class="m-0">{{__("masshtab")}}: <span class="font-normal"> {{ item.masshtab}} </span></p>
                            </div>
                            <div class="col-span-1 karzinka-close cursor-pointer text-right pt-1 my-auto" @click="removeStoreMD(item)">
                                <svg class="ml-auto" xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.602" viewBox="0 0 13.75 13.602">
                                    <g transform="translate(-0.02 0.006)">
                                        <path
                                                d="M.074,1.289C.074,2,.617,2.383,1.1,2.867c.3.3,3.819,3.786,3.914,3.928C4.918,6.939.9,10.921.456,11.366a1.412,1.412,0,0,0-.284.36A1.268,1.268,0,0,0,.02,12.3v.1a1.246,1.246,0,0,0,.355.861,1.406,1.406,0,0,0,1.1.328,1.376,1.376,0,0,0,.88-.409L5.572,9.957l.645-.645a7.842,7.842,0,0,0,.651-.665c.172.115,4.03,4.037,4.558,4.546a1.474,1.474,0,0,0,2,.024,1.438,1.438,0,0,0,.345-.786V12.2a1.377,1.377,0,0,0-.423-.848L10.406,8.412c-.06-.06-.1-.1-.161-.162s-.116-.09-.189-.16L8.748,6.767c.149-.1,4.061-4.049,4.519-4.5a1.241,1.241,0,0,0,.262-.383A1.3,1.3,0,0,0,12.454.027a1.257,1.257,0,0,0-1.068.409l-3.84,3.84a7.64,7.64,0,0,1-.651.638c-.218-.146-.984-1.02-1.3-1.281a1.788,1.788,0,0,1-.174-.149L2.363.423a1.318,1.318,0,0,0-2.289.866Z"
                                                transform="translate(0 0)" fill="currentColor"
                                                fill-rule="evenodd"/>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <a v-if="meta_data.length > 0" href="javascript:void(0)" uk-toggle @click="toCheckout"
                class="cursor-pointer flex items-center text-[#fff] text-[16px] font-medium bg-[#614CA7] rounded-[7px] mt-[18.3px] justify-center w-[170px] h-[40px] td">
                    {{__("order")}}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: "Basket",
        components: [],
        data() {
            return {
                meta_data: [],
            }
        },

        methods: {
            getStoreMD() {
                return this.$store.getters.getSIMetaData
            },

            removeStoreMD(item) {
                this.$store.commit('removeStore', item)
                this.meta_data = this.getStoreMD()
            },

            toCheckout() {
                this.$router.push({name: 'checkout'})
            }
        },

        mounted() {
            this.meta_data = this.getStoreMD()
        }
    }
</script>

<style scoped>
    /*
          Enter and leave animations can use different
          durations and timing functions.
        */
    .slide-fade-enter-active {
        transition: all 0.3s ease-out;
    }

    .slide-fade-leave-active {
        transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .slide-fade-enter-from,
    .slide-fade-leave-to {
        transform: translateX(100px);
        opacity: 0;
    }

    .karzinka-close {
        right: -45px;
        color: #bbb !important;
    }

    .karzinka-close:hover {
        color: #6C4BD8 !important;
        transition: all 0.5s;
    }
</style>