<template>
  <div class="uk-section">
    <slot></slot>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Section"
}
</script>

<style scoped>

</style>