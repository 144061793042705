<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import UIkit from 'uikit/dist/js/uikit.min';
import Icons from 'uikit/dist/js/uikit-icons'
// import Index from "@/components/Home/Index";

UIkit.use(Icons);
export default {
  name: 'App',
  // components: {Index},
  methods: {
    init() {
        if (!localStorage.getItem('lang'))
            localStorage.setItem('lang', 'uz')
    }
  },
  mounted() {
      this.init();
  }
}
</script>
<style lang="css">
@import url('~uikit/dist/css/uikit.min.css');
@import url('./style/style.css');
@import url('./style/output.css');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
/**{*/
/*    font-family: 'Montserrat', sans-serif!important;*/
/*}*/
body{
    background: white;
}
.uk-container {
    max-width: 1364px!important;
}
</style>