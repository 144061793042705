<template>
    <Navbar :publications="publications"/>
        <Container class="mb-[100px] relative">
            <Breadcrumbs>
                <template #link>
                    <a class="text-[#000] font-medium hover:no-underline hover:text-[#614CA7]" href="javascript:void(0);">
                        {{__("about_the_organization")}}
                    </a>
                </template>
            </Breadcrumbs>
            <div>
                <div class="text-[18px] text-[#614CA7] font-extrabold leading-[22px] uppercase mt-[36px] relative dkg-bottom-line">
                    {{ __("about_the_organization") }}
                </div>
                <div class="mt-[41px]">
                    <div v-html="content"></div>
                </div>
            </div>
        </Container>
        <Footer :static_info="static_info" :cur_lang="cur_lang"/>
</template>

<script>

import Container from "@/components/Block/Container";
import Breadcrumbs from "@/components/Block/Breadcrumbs";
import Navbar from "@/components/Layouts/Navbar";
import Footer from "@/components/Layouts/Footer";
import axios from "axios";
import {apiUrls} from "@/utils/const/apiUrls";
import mixin from "@/boot/boot-mixin";

export default {
    name: "DkgPage",
    components: {Container, Breadcrumbs, Navbar, Footer},
    mixins: [mixin],
    data() {
        return {
            publications: [],
            about: [],
            content: {},
            system_settings: [],
            static_info: new Map(),
            cur_lang: localStorage.getItem('lang')
        }
    },
    created() {
        axios.get(apiUrls.PUBLICATIONS + "?" + this.setParams({'language': localStorage.getItem('lang')}))
                .then(response => {
                    this.publications = response.data.data;
                }).catch(error => {
                console.log(error);
                console.log(error)
            }).finally(() => {
            });

        axios.get(apiUrls.SYSTEM_SETTINGS + "?" + this.setParams({'language': localStorage.getItem('lang')}))
            .then(response => {
                this.system_settings = response.data.data;
                for (let i = 0; i < response.data.total; i++) {
                    this.static_info.set(response.data.data[i].name, response.data.data[i].value)
                }
            }).catch(error => {
            console.log(error);
            console.log(error)
        }).finally(() => {
        });
    },
    methods: {
        getData() {
            let filter = {}
            axios.get(apiUrls.ABOUT_US, filter)
                .then(res => {
                    this.about = res.data.data;
                    this.content = res.data.data[0].content
                })
                .catch(function (error) {
                    (apiUrls.ABOUT_US)
                    console.log(error)
                })
        },
    },
    mounted() {
        this.getData()
    }

}
</script>

<style scoped>
    .dkg-bottom-line:after{
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #DEDEDE;
        right: 0;
        bottom: -85%;
    }
</style>
