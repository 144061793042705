<template>
  <Navbar :publications="publications"/>
  <Container class="md:mb-[100px] relative">
    <Breadcrumbs>
      <template #link>
        <a class="text-[#000] font-medium hover:no-underline hover:text-[#614CA7]" href="javascript:void(0);">
          {{ __("management_and_staff") }}
        </a>
      </template>
    </Breadcrumbs>
    <div v-if="spinner" class="uk-container mx-auto text-center">
      <div uk-spinner="ratio: 3"></div>
    </div>
    <div v-if="!spinner">
      <div
          class="text-[18px] text-[#614CA7] font-extrabold leading-[22px] uppercase md:mt-[36px] relative workers-line-a">
        {{ __("management_and_staff") }}
      </div>
      <WorkersCard v-for="(worker, index) in workers" :work_time="worker.work_time" :key="index">
        <template #img>
          <img class="w-[170px] h-[174px] rounded-full" :src="worker.img_url" alt="avatar">
        </template>
        <template #name>{{ worker.fio }}</template>
        <template #specialty>{{ worker.staff_type }}</template>
        <template #email>
          <a class="text-[14px] font-normal text-[#614CA7]" href="mailto:exemple@gmail.com"> {{ worker.email == null ? '--' : worker.email }}</a>
        </template>
        <template #phone>
          <a class="text-[14px] font-normal text-[#614CA7]" :href="`tel:+998${worker.phone}`">
            {{ ' +998 ' + worker.phone }}</a>
        </template>
        <template #work_time>
          {{ worker.work_time }}
        </template>
      </WorkersCard>
    </div>
  </Container>
  <Footer :static_info="static_info" :cur_lang="cur_lang"/>
</template>

<script>

import Container from "@/components/Block/Container";
import Breadcrumbs from "@/components/Block/Breadcrumbs";
import Navbar from "@/components/Layouts/Navbar";
import Footer from "@/components/Layouts/Footer";
import WorkersCard from "@/components/Block/WorkersCard";
import axios from "axios";
import {apiUrls} from "@/utils/const/apiUrls";
import mixin from "@/boot/boot-mixin";

export default {
  name: "WorkersPage",
  components: {Container, Breadcrumbs, Navbar, Footer, WorkersCard},
  mixins: [mixin],
  data() {
    return {
      publications: [],
      workers: [],
      path: '',
      spinner: true,
      system_settings: [],
      static_info: new Map(),
      cur_lang: localStorage.getItem('lang')
    }
  },
  created() {
    axios.get(apiUrls.PUBLICATIONS + "?" + this.setParams({'language': localStorage.getItem('lang')}))
        .then(response => {
          this.publications = response.data.data;
        }).catch(error => {
      console.log(error);
      console.log(error)
    }).finally(() => {
    });

    axios.get(apiUrls.SYSTEM_SETTINGS + "?" + this.setParams({'language': localStorage.getItem('lang')}))
        .then(response => {
          this.system_settings = response.data.data;
          for (let i = 0; i < response.data.total; i++) {
            this.static_info.set(response.data.data[i].name, response.data.data[i].value)
          }
        }).catch(error => {
      console.log(error);
      console.log(error)
    }).finally(() => {
    });
  },
  methods: {
    getStaffs() {
      let filter = {}
      axios.get(apiUrls.STAFFS, filter)
          .then(res => {
            // console.log(res.data.data)
            this.workers = res.data.data;
            this.spinner = false
            // console.log(this.workers);
          })
          .catch(function (error) {
            // console.log(apiUrls.STAFFS);
            console.log(error);
          })
          .finally(() => this.spinner = false)
    },
  },

  mounted() {
    this.getStaffs()
  }
}
</script>

<style scoped>
/* .workers-line-a::after{
    content: '';
    position: absolute;
    width: 99%;
    height: 1px;
    left: 0;
    right: 0;
    background-color: #DEDEDE;
    /* margin-right: 40px; */
/* margin-left: 40px; */
/* bottom: -193%;
} */
/* .workers-line-b::after{
    content: '';
    position: absolute;
    width: 93.5%;
    height: 1px;
    left: 0;
    right: 0;
    top: 103.7%;
    background-color: #DEDEDE;
    margin-right:40px;
    margin-left:40px;
}  */
/* .workers-right-line .line::after{
    content: '';
    position: absolute;
    width: 1px;
    height: 428.5px;
    background-color: #DEDEDE;
    top: 15.7%;
    margin-left: 22.5%;
} */

/*bad-*/
/* .line::after{
    content: '';
    position: absolute;
    width: 1px;
    height: 428.5px;
    background-color: #DEDEDE;
    top: 28%;
    margin-left: 22.5%;
} */
/*bad-*/
/* .last-line:last-child .last:after{
    display: none;
} */
</style>
