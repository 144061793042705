<template>
    <Section class="bg-white" id="news">
        <Container>
            <Heading class="md:mt-12 lg:mt-12">
                <template #title>
                    {{__("news")}}
                </template>
            </Heading>

            <Grid class="uk-grid-medium justify-center uk-child-width-1-3@l uk-child-width-1-2@m"
                  uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: .uk-cards; delay: 300; repeat: true">
                <div class="uk-cards" v-for="(pub, index) in publications" :key="index">
                    <NewsCard>
                        <template #tag>
                            {{pub.header}}
                        </template>
                        <template #content>
                            {{pub.short_content}}
                        </template>
                        <template #img>
                            <img alt="earth" v-if="pub.resources_id != null" :src="ImgPath(pub.resources_id)"
                                 uk-img="target: #news"
                                 class="mx-auto card_image earth">
                            <img alt="earth" v-else src="../../assets/earth.png" uk-img="target: #news"
                                 class="mx-auto card_image">
                        </template>
                        <template #date>
                            {{pub.created_day}}<span
                                class="text-[#FFFFFF] text-[16px] font-regular block leading-[19px] text-center">{{getMonth(pub.created_month)}}</span>
                        </template>
                        <template #button>
                            <div @click.stop="toNewsPage(pub)">{{__('more')}}</div>
                        </template>
                    </NewsCard>
                </div>
            </Grid>
        </Container>
    </Section>
</template>

<script>
    import {defineComponent} from 'vue'
    import NewsCard from "@/components/Block/NewsCard";
    import Grid from "@/components/Block/Grid";
    import Heading from "@/components/Layouts/Heading";
    import Container from "@/components/Block/Container";
    import Section from "@/components/Block/Section";
    import {baseUrl} from "@/utils/const/apiUrls";
    // import {Carousel, Navigation, Slide} from 'vue3-carousel'

    import 'vue3-carousel/dist/carousel.css'

    export default defineComponent({
        // eslint-disable-next-line vue/multi-word-component-names
        name: "News",
        components: {NewsCard, Section, Container, Heading, Grid},
        props: {
            publications: Array,
        },
        data() {
            return {
                breakpoints: {
                    300: {
                        itemsToShow: 1,
                    },
                    400: {
                        itemsToShow: 2,
                    },

                    500: {
                        itemsToShow: 3,
                    },
                },

                months: [
                    {
                        id: 1,
                        name: 'january'
                    },
                    {
                        id: 2,
                        name: 'february'
                    },
                    {
                        id: 3,
                        name: 'march'
                    },
                    {
                        id: 4,
                        name: 'april'
                    },
                    {
                        id: 5,
                        name: 'may'
                    },
                    {
                        id: 6,
                        name: 'june'
                    },
                    {
                        id: 7,
                        name: 'july'
                    },
                    {
                        id: 8,
                        name: 'august'
                    },
                    {
                        id: 9,
                        name: 'september'
                    },
                    {
                        id: 10,
                        name: 'october'
                    },

                    {
                        id: 11,
                        name: 'november'
                    },

                    {
                        id: 12,
                        name: 'december'
                    },

                ],
            }
        },
        methods: {
            toNewsPage(row) {
                this.$router.push({
                    name: 'news',
                    params: {id: row.id}
                })
            },

            getMonth(val) {
                let result = this.months.filter(obj => {
                    return obj.id === val
                });
                return result[0].name.substr(0, 3)
            },


            ImgPath(value) {
                if (value === null) {
                    return null;
                }
                return baseUrl.url + '/resources/' + 'view?resource_id=' + value
            },
        },
    })
</script>

<style>
    .earth {
        width: 427px;
        height: 242px;
        object-fit: cover;
        border-radius: 5px 4px 0px 0px;
    }
    .carousel__next {
    right: -50px;
    }
</style>
