module.exports = {
    methods: {
        /**
         * Translate the given key.
         */
        __(key, replace = {}) {
            var translation = localStorage.getItem('lang')
                ? (require('./public/lang/' + String(localStorage.getItem('lang')) + '.json'))[key]
                : key
            Object.keys(replace).forEach(function (key) {
                translation = translation.replace(':' + key, replace[key])
            });
            return translation
        },
    },
}