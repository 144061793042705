<template>
  <div v-if="active">
    <Navbar @changeLanguage="changeLanguage" :publications="publications"/>
    <section class="heading-section">
      <div class="uk-container">
        <div class="w-50p space-y-6 my-16">
          <div class="fs-30 text-[#fff] font-light md:leading-[37px]"
               uk-scrollspy="cls: uk-animation-slide-left-medium; delay:100; repeat: true">

            <template v-if="cur_lang == 'uz'">
              <div v-html="static_info.get('slide_content_uz')"></div>
            </template>
            <template v-else-if="cur_lang == 'ru'">
              <div v-html="static_info.get('slide_content_ru')"></div>
            </template>
            <template v-else>
              <div v-html="static_info.get('slide_content_en')"></div>
            </template>
          </div>
          <div class="md:text-[16px] text-[#FFFFFF] font-light md:leading-[25px] md:mr-16"
               uk-scrollspy="cls: uk-animation-slide-left-medium; delay:500; repeat: true">

            <template v-if="cur_lang == 'uz'">
              {{ static_info.get('slide_content_text_uz') }}
            </template>
            <template v-else-if="cur_lang == 'ru'">
              {{ static_info.get('slide_content_text_ru') }}
            </template>
            <template v-else>
              {{ static_info.get('slide_content_text_en') }}
            </template>
          </div>
        </div>
      </div>
    </section>
    <Service/>
    <News :publications="publications"/>
    <Statistics/>
    <FormInput/>
    <BottomSlider/>
    <Footer :static_info="static_info" :cur_lang="cur_lang"/>
  </div>
</template>

<script>
import Navbar from "@/components/Layouts/Navbar";
import Footer from "@/components/Layouts/Footer";
import FormInput from "@/components/Layouts/FormInput";
import Service from "@/components/Layouts/Service";
import BottomSlider from "@/components/Layouts/BottomSlider";
import News from "@/components/Home/News";
import Statistics from "@/components/Home/Statistics";
import axios from "axios";
import {apiUrls} from "@/utils/const/apiUrls";
import mixin from "@/boot/boot-mixin";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Index",
  components: {
    News,
    Navbar,
    FormInput,
    Footer,
    Statistics,
    Service,
    BottomSlider
  },
  mixins: [mixin],
  data() {
    return {
      active: true,
      publications: [],
      system_settings: [],
      static_info: new Map(),
      cur_lang: localStorage.getItem('lang'),
      xs: false,
    }
  },
  created() {
    axios.get(apiUrls.PUBLICATIONS + "?" + this.setParams({'language': localStorage.getItem('lang')}))
        .then(response => {
          this.publications = response.data.data;
          // console.log(this.publications);
        }).catch(error => {
      console.log(error);
      console.log(error)
    }).finally(() => {
    });

    axios.get(apiUrls.SYSTEM_SETTINGS + "?" + this.setParams({'language': localStorage.getItem('lang')}))
        .then(response => {
          this.system_settings = response.data.data;
          for (let i = 0; i < response.data.total; i++) {
            this.static_info.set(response.data.data[i].name, response.data.data[i].value)
          }
        }).catch(error => {
      console.log(error);
      console.log(error)
    }).finally(() => {
    });

  },
  methods: {
    changeLanguage(lang) {
      localStorage.setItem('lang', lang)
      this.active = false;
      this.$nextTick(() => {
        // Adding the component back in
        this.active = true;
      });
      window.location.reload()
    },
    windowSize(){
      let size = window.innerWidth
      this.xs = size < 600
    }
  },
  mounted() {
  },
}
</script>

<style scoped>
.heading-section {
  background-image: url('../../assets/heading.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
  height: 570px;
}

.header-card::after {
  content: '';
  position: absolute;
  display: block;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #DFDFDF;
  width: 2px;
  height: 70px;
}

.header-card:last-child::after {
  display: none;
}

.heading-section::after {
  content: '';
  position: absolute;
  z-index: -1;
  opacity: .57;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.w-50p {
  width: 50%;
}

.fs-30 {
  font-size: 30px !important;
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .heading-section {
    height: 400px;
  }
}
@media (min-width: 600px) and (max-width: 1023px) {
  .w-50p {
    width: 70%;
  }
  .heading-section{
    height: 400px;
  }
}
@media (max-width: 599px) {
  .w-50p{
    width: 70%;
  }
  .heading-section{
    height: 400px;
    background-position: top left;
  }
}


</style>
