export default {
    data() {
        return {
            msg: 'Hello World',
        }
    },
    created: function () {

    },
    methods: {
        setParams(value) {
            if (value) {
                return Object.keys(value).map(key => key + '=' + value[key]).join('&');
            }
        },
        number_format(number, decimals, thousands_sep) {
            return number ? number.toFixed(decimals ? decimals : 2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + (thousands_sep ? thousands_sep : ' ')) : "0"
        },

        optionPage(parObj) {
            let res = '?';
            Object.keys(parObj).forEach(keys => {
                res += keys + '=' + parObj[keys] + '&'
            })
            // res += "language=" + store.state.language
            return res;
        },

        setDateWithTime(value) {
            let val = value * 1
            let date = new Date(val)
            let day = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate();
            let mes = (date.getMonth() + 1) >= 10 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
            let year = date.getFullYear();
            let hour = date.getHours();
            let minutes = date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes();
            let second = date.getSeconds() >= 10 ? date.getSeconds() : '0' + date.getSeconds()
            return day + '.' + mes + '.' + year + '  ' + hour + ':' + minutes + ':' + second
        },

        datetime(value) {
            if (value) {
                const d = new Date(value);
                let day = this.checkSingleDigit(d.getDate());
                let month = this.checkSingleDigit(d.getMonth() + 1);
                let year = d.getFullYear();
                let hours = this.checkSingleDigit(d.getHours());
                let minutes = this.checkSingleDigit(d.getMinutes());
                return `${day}.${month}.${year} ${hours}:${minutes}`;
            }
        },

        checkSingleDigit(digit) {
            return ('0' + digit).slice(-2);
        },

        showInfo(message) {
            this.$refs.alert.showAlert(
                'success', // There are 4 types of alert: success, info, warning, error
                message, // Message of the alert
                'Muvaffaqiyatli', // Header of the alert
                {
                    iconSize: 35, // Size of the icon (px)
                    iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
                    position: 'top right'
                } // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right'
            )
        },

        showErrorInfo(message) {
            this.$refs.alert.showAlert(
                'error', // There are 4 types of alert: success, info, warning, error
                message, // Message of the alert
                'Xatolik', // Header of the alert
                {
                    iconSize: 35, // Size of the icon (px)
                    iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
                    position: 'top right'
                } // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right'
            )
        },

        showWarningInfo(message) {
            this.$refs.alert.showAlert(
                'warning', // There are 4 types of alert: success, info, warning, error
                message, // Message of the alert
                'Ogohlantirish', // Header of the alert
                {
                    iconSize: 35, // Size of the icon (px)
                    iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
                    position: 'top right'
                } // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right'
            )
        },
    },
}

