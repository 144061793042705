export const baseUrl =
  {
    // url: 'http://192.168.15.37:8088/api',
    url: location.origin.includes('localhost') ? 'https://geofond.kadastr.uz/api' : 'https://geofond.kadastr.uz/api',
    timeout: 60 * 60 * 20,
    UPLOAD_TIMEOUT: 2 * 60 * 1000,
  }
export const apiUrls = {
  //base
  USER_TYPES: baseUrl.url + '/user-types',
  USERS: baseUrl.url + '/users',
  MODULES: baseUrl.url + '/modules',
  AUTH: baseUrl.url + '/auth',
  SESSIONS: baseUrl.url + '/sessions',
  APP_ACTIONS_GROUP: baseUrl.url + '/app-action-groups',
  APP_ACTIONS: baseUrl.url + '/app-actions',
  USER_ROLES: baseUrl.url + '/user-roles',
  REGIONS: baseUrl.url + '/regions',
  AREAS: baseUrl.url + '/areas',
  APP_ACCESS: baseUrl.url + '/app-access',
  BLOG_AND_NEWS: baseUrl.url + '/blog-and-news',
  BLOG_AND_NEWS_COMMENTS: baseUrl.url + '/blog-and-news-comments',
  SYSTEM_SETTINGS: baseUrl.url + '/system-settings',
  SYSTEM_SETTING_TYPES: baseUrl.url + '/system-settings-types',
  SITE_MENUS: baseUrl.url + '/site-menus',

  //file reource
  FILES: baseUrl.url + '/file',
  UPLOAD_RESOURCE: baseUrl.url + "/resources/upload",
  RESOURCE: baseUrl.url + "/resources",
  RESOURCE_VIEW: baseUrl.url + "/admin/resources-view",

  //file geofond
  SOURCES: baseUrl.url + '/sources',
  OBJECT_TYPES: baseUrl.url + '/object-types',
  PUBLICATIONS: baseUrl.url + '/publications',
  SERVICES: baseUrl.url + '/services',
  PUBLICATION_TYPES: baseUrl.url + '/publication-types',
  STAFFS: baseUrl.url + '/staffs',
  ABOUT_US: baseUrl.url + '/about-company',
  ORGANIZATIONS: baseUrl.url + '/organizations',
  BTS_REGIONS: baseUrl.url + '/bts-region',
  BTS_AREAS: baseUrl.url + '/bts-area',

  //file one-id
  ONE_ID_OAUTH2: baseUrl.url + '/oauth-one-id/v2',
  REQUESTS: baseUrl.url + '/requests',
}



