<template>
    <div>
    <section class="max-w-[1364px] mx-auto mb-[417px]">
        <div class="uk-flex" uk-filter="target: .js-filter">
            <div class="w-[280px] mr-[40px] flex flex-col">
                <div class="uk-flex items-center pt-[12.5px] pb-[16.5px] border-b border-[#E0DBF9]">
                    <div>
                        <img src="@/assets/cabinet-icon.svg" alt="cabinet icon"
                                class="bg-[#F7F7FF] rounded-full" >
                    </div>
                    <div class="text-[#000] text-[16px] font-semibold ml-[25px]">
                        {{user.fio}}
                    </div>
                </div>
                <router-link to="" class="td hover:bg-[#F7F7FF] text-[#676767]
                        hover:text-[#6848D2]" uk-filter-control=".personalDate">
                    <div class="uk-flex items-center  border-b border-[#E0DBF9]  pt-[46.5px] pb-[16.5px] "
                            style="transition: all .5s"
                    >
                        <div class="ml-[24px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.164" height="21.704"
                                    viewBox="0 0 22.164 21.704">
                                <g transform="translate(0 0.009)">
                                    <g transform="translate(0 -0.009)">
                                        <path
                                                d="M2916.929,5.434a4.139,4.139,0,0,1,.355-1.581,3.9,3.9,0,0,1,1.936-1.936,3.776,3.776,0,0,1,2.441-.246,3.9,3.9,0,0,1,.721.246,3.714,3.714,0,0,1,1.141.795,3.828,3.828,0,0,1,1.041,1.863,3.776,3.776,0,0,1-.246,2.441,3.864,3.864,0,0,1-7.388-1.581Zm-1.549-.242a5.266,5.266,0,0,0,1.585,4.077,5.424,5.424,0,0,0,8.739-1.5,5.529,5.529,0,0,0,.016-4.647,5.187,5.187,0,0,0-1.085-1.528,5.138,5.138,0,0,0-1.5-1.069A5.453,5.453,0,0,0,2915.9,3.1,5.036,5.036,0,0,0,2915.38,5.192Z"
                                                transform="translate(-2909.96 0.009)" fill="currentColor"
                                                fill-rule="evenodd"/>
                                        <path
                                                d="M0,6682.719v1.936a.8.8,0,0,0,.677.678H11.324c.351,0,.677-.336.677-.871a.8.8,0,0,0-.677-.678H1.549a5.934,5.934,0,0,1,.957-3.834,5.468,5.468,0,0,1,2.278-1.884,8.2,8.2,0,0,1,3.685-.477,24.662,24.662,0,0,0,3.085-.061.786.786,0,0,0-.23-1.488H6.678a6.774,6.774,0,0,0-3.726,1.259,7.121,7.121,0,0,0-1.319,1.2A6.991,6.991,0,0,0,0,6682.719Z"
                                                transform="translate(0 -6663.628)" fill="currentColor"
                                                fill-rule="evenodd"/>
                                        <path
                                                d="M7541.395,6005.824c0-.352.049-.537.049-.919.187-.05.506-.46.684-.624l.025-.023.24-.243c.342-.317.623-.611.95-.937l1.875-1.851.786.763-3.674,3.706A2.645,2.645,0,0,1,7541.395,6005.824Zm6.533-3.824c0-.4-.3-.617-.52-.834l-1.355-1.355c-.319-.319-.387-.52-.932-.52-.4,0-1.594,1.4-1.974,1.753-.034.032-.077.066-.1.091l-.447.423c-.061.058-.061.062-.119.124l-.581.581-.047.049c-.043.046-.059.067-.092.1l-1.69,1.7c-.34.408-.152,1.092-.235,1.7-.074.538-.117,1.095.216,1.407.317.3.906.217,1.4.162,1.693-.19,1.009.4,2.4-.987l3.388-3.388C7547.493,6002.75,7547.928,6002.466,7547.928,6002Z"
                                                transform="translate(-7525.764 -5988.137)" fill="currentColor"
                                                fill-rule="evenodd"/>
                                        <path
                                                d="M6871.97,10841.557c0,.51.273.871.678.871h7.162c.351,0,.678-.336.678-.871a.8.8,0,0,0-.678-.678h-7.162A.8.8,0,0,0,6871.97,10841.557Z"
                                                transform="translate(-6859.194 -10820.725)" fill="currentColor"
                                                fill-rule="evenodd"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="font-semibold ml-[49.8px]">
                            {{__("personal_information")}}
                        </div>
                    </div>
                </router-link>
                <router-link to="" class="td uk-active hover:bg-[#F7F7FF] text-[#676767]
                        hover:text-[#6848D2]" uk-filter-control=".meta-data">
                    <div class="uk-flex items-center  border-b border-[#E0DBF9]
                                pt-[17.5px] pb-[17.3px]"
                            style="transition: all .5s"
                    >
                        <div class="ml-[24px]">
                            <span class="uk-icon font-bold" uk-icon="icon: folder; ratio: 1.25"></span>
                            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="22.164" height="22.164"
                                viewBox="0 0 22.164 22.164">
                                <g transform="translate(0 0.01)">
                                    <g transform="translate(0 -0.01)">
                                        <path
                                                d="M0,3.28V18.864a3.471,3.471,0,0,0,3.29,3.29h5.8a.87.87,0,0,0,.257-1.685c-.321-.115-4.631-.048-5.451-.046-1.027,0-1.885-.328-2.1-1.361a2.944,2.944,0,0,1-.062-.631V3.713A1.8,1.8,0,0,1,3.723,1.722h10.13c2.436,0,1.991,2.3,1.991,4.026l0,4.542a.874.874,0,0,0,1.728-.126V3.323A3.371,3.371,0,0,0,16.141.645a2.758,2.758,0,0,0-.513-.309A2.969,2.969,0,0,0,14.285-.01h-11A3.471,3.471,0,0,0,0,3.28Z"
                                                transform="translate(0 0.01)" fill="currentColor"
                                                fill-rule="evenodd"/>
                                        <path
                                                d="M2755.695,2782.178l.244-.8c.406-1.249.01-.649,1.3-1.942.142-.142,1.965-1.97,2.176-2.11l1.179,1.158c-.142.208-3.115,3.16-3.22,3.208S2755.918,2782.071,2755.695,2782.178Zm4.968-6.093a.853.853,0,1,1,1.179,1.158Zm-7.132,7.348a.85.85,0,0,0,.952.866,5.342,5.342,0,0,0,.832-.207l2.54-.707c.69-.2.731-.353,1.25-.872l3.225-3.225c.913-.913,1.677-1.407,1.677-2.911a2.592,2.592,0,0,0-2.468-2.467c-1.5,0-1.98.746-2.889,1.656l-3.789,3.787a4.15,4.15,0,0,0-.613,1.552c-.084.293-.173.538-.257.825A12.514,12.514,0,0,0,2753.53,2783.433Z"
                                                transform="translate(-2741.842 -2762.135)" fill="currentColor"
                                                fill-rule="evenodd"/>
                                        <path
                                                d="M2309.328,1316.392a.838.838,0,0,0,.823.909h8.313a.867.867,0,0,0,0-1.732h-8.313A.838.838,0,0,0,2309.328,1316.392Z"
                                                transform="translate(-2305.174 -1309.986)" fill="currentColor"
                                                fill-rule="evenodd"/>
                                        <path
                                                d="M2315,2570.772a.838.838,0,0,0,.823.909h2.641a.867.867,0,0,0,0-1.732h-2.641A.838.838,0,0,0,2315,2570.772Z"
                                                transform="translate(-2305.174 -2559.041)" fill="currentColor"
                                                fill-rule="evenodd"/>
                                    </g>
                                </g>
                            </svg> -->
                        </div>
                        <div class="font-semibold ml-[49.8px]">
                            {{__("meta_data")}}
                        </div>
                    </div>
                </router-link>
                <router-link to="" class="td uk-active hover:bg-[#F7F7FF] text-[#676767]
                        hover:text-[#6848D2]" uk-filter-control=".my-sale">
                    <div class="uk-flex items-center  border-b border-[#E0DBF9]
                                pt-[17.5px] pb-[17.3px] "
                            style="transition: all .5s"
                    >
                        <div class="ml-[24px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.164" height="22.164"
                                    viewBox="0 0 22.164 22.164">
                                <g transform="translate(0 0.01)">
                                    <g transform="translate(0 -0.01)">
                                        <path
                                                d="M0,3.28V18.864a3.471,3.471,0,0,0,3.29,3.29h5.8a.87.87,0,0,0,.257-1.685c-.321-.115-4.631-.048-5.451-.046-1.027,0-1.885-.328-2.1-1.361a2.944,2.944,0,0,1-.062-.631V3.713A1.8,1.8,0,0,1,3.723,1.722h10.13c2.436,0,1.991,2.3,1.991,4.026l0,4.542a.874.874,0,0,0,1.728-.126V3.323A3.371,3.371,0,0,0,16.141.645a2.758,2.758,0,0,0-.513-.309A2.969,2.969,0,0,0,14.285-.01h-11A3.471,3.471,0,0,0,0,3.28Z"
                                                transform="translate(0 0.01)" fill="currentColor"
                                                fill-rule="evenodd"/>
                                        <path
                                                d="M2755.695,2782.178l.244-.8c.406-1.249.01-.649,1.3-1.942.142-.142,1.965-1.97,2.176-2.11l1.179,1.158c-.142.208-3.115,3.16-3.22,3.208S2755.918,2782.071,2755.695,2782.178Zm4.968-6.093a.853.853,0,1,1,1.179,1.158Zm-7.132,7.348a.85.85,0,0,0,.952.866,5.342,5.342,0,0,0,.832-.207l2.54-.707c.69-.2.731-.353,1.25-.872l3.225-3.225c.913-.913,1.677-1.407,1.677-2.911a2.592,2.592,0,0,0-2.468-2.467c-1.5,0-1.98.746-2.889,1.656l-3.789,3.787a4.15,4.15,0,0,0-.613,1.552c-.084.293-.173.538-.257.825A12.514,12.514,0,0,0,2753.53,2783.433Z"
                                                transform="translate(-2741.842 -2762.135)" fill="currentColor"
                                                fill-rule="evenodd"/>
                                        <path
                                                d="M2309.328,1316.392a.838.838,0,0,0,.823.909h8.313a.867.867,0,0,0,0-1.732h-8.313A.838.838,0,0,0,2309.328,1316.392Z"
                                                transform="translate(-2305.174 -1309.986)" fill="currentColor"
                                                fill-rule="evenodd"/>
                                        <path
                                                d="M2315,2570.772a.838.838,0,0,0,.823.909h2.641a.867.867,0,0,0,0-1.732h-2.641A.838.838,0,0,0,2315,2570.772Z"
                                                transform="translate(-2305.174 -2559.041)" fill="currentColor"
                                                fill-rule="evenodd"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="font-semibold ml-[49.8px]">
                            {{__("my_applications")}}
                        </div>
                    </div>
                </router-link>
                <router-link to="" class="td" @click="logout">
                    <div class="uk-flex items-center  border-b border-[#E0DBF9] text-[#676767]
                        hover:text-[#6848D2] pt-[16.5px] pb-[18.3px] hover:bg-[#F7F7FF]"
                        style="transition: all .5s"
                    >
                        <div class="ml-[24px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.251" height="22.164"
                                viewBox="0 0 22.251 22.164">
                                <g transform="translate(0 0.06)">
                                    <g transform="translate(0 -0.06)">
                                        <path
                                                d="M0,2.548V19.5A2.775,2.775,0,0,0,2.564,22.1h8.6A.925.925,0,0,0,11.3,20.28c-.62-.152-7.116.022-8.6-.044a.876.876,0,0,1-.824-.914V2.721a.878.878,0,0,1,.783-.912c.664-.05,4.359,0,5.215,0a30.628,30.628,0,0,0,3.4-.037A.925.925,0,0,0,11.169-.06h-8.6A2.778,2.778,0,0,0,.26,1.5,2.636,2.636,0,0,0,0,2.548Z"
                                                transform="translate(0 0.06)" fill="currentColor"
                                                fill-rule="evenodd"/>
                                        <path
                                                d="M4532.733,2821.286c0,.532.6.974.9,1.271.192.192.353.351.543.544l.587.543c.326.327,2.049,2,2.183,2.206-.838,0-10.305-.07-10.9.053a.931.931,0,0,0,0,1.805c.593.123,10.06.053,10.9.053l-2.444,2.467c-.118.109-.184.138-.305.26-.521.524-1.466,1.277-1.466,1.837a.921.921,0,0,0,.913.956c.529,0,.736-.323,1.01-.6l1.2-1.2c.158-.16.28-.234.435-.391l2.846-2.847c.47-.471,1.076-.79,1.076-1.489,0-.434-.384-.758-.619-.99l-.826-.781-1.63-1.63c-.278-.279-.527-.524-.8-.8-.152-.154-.27-.226-.415-.368l-1.215-1.218c-.259-.259-.523-.641-1.01-.641A.92.92,0,0,0,4532.733,2821.286Z"
                                                transform="translate(-4517.958 -2815.723)" fill="currentColor"
                                                fill-rule="evenodd"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="font-semibold ml-[49.8px]">
                            {{__("exit")}}
                        </div>
                    </div>
                </router-link>
            </div>

            <div class="js-filter max-w-[1050px] w-full">
                <div class="w-full h-[337px] bg-[#F7F7FF] border
                        border-[#E0DBF9] border-t-0 personalDate"
                >
                    <div class="max-w-[850px] w-full mx-auto">
                        <div class="border-b border-[#E0DBF9] pb-[40.5px] pt-[37.5px]
                                text-[#000] font-semibold text-[18px]"
                        >
                        {{__("personal_information")}}
                        </div>
                        <div class="uk-flex mt-[38.5px]">
                            <div class="flex flex-col ">
                                <div class="text-[#000] font-semibold h-[32px]
                                        border-r border-[#E0DBF9] w-[120px]"
                                >
                                    {{__("login")}}
                                </div>
                                <div class="text-[#000] font-semibold h-[32px]
                                        border-r border-[#E0DBF9] w-[120px] my-[26px]"
                                >
                                {{__("fio_name")}}
                                </div>
                                <div class="text-[#000] font-semibold h-[32px]
                                        border-r border-[#E0DBF9] w-[120px]"
                                >
                                    {{__("fio_surname")}}
                                </div>
                            </div>
                            <div>
                                <div class="text-[#000] font-medium pl-[36.5px] h-[32px]">
                                    {{user.login}}
                                </div>
                                <div class="text-[#000] font-medium pl-[36.5px] h-[32px] my-[26px]">
                                    {{first_name}}
                                </div>
                                <div class="text-[#000] font-medium pl-[36.5px] h-[32px]">
                                    {{last_name}}
                                </div>
                            </div>
                            <div class="flex flex-col ml-[120px]">
                                <div class="text-[#000] font-semibold h-[32px]
                                        border-r border-[#E0DBF9] w-[170px]"
                                >
                                    {{__("email")}}
                                </div>
                                <div class="text-[#000] font-semibold h-[32px]
                                        border-r border-[#E0DBF9] w-[170px] my-[26px]"
                                >
                                    {{__("phone")}}
                                </div>
                                <!--                                    <div class="text-[#000] font-semibold h-[32px]-->
                                <!--                                            border-r border-[#E0DBF9] w-[225px]"-->
                                <!--                                    >-->
                                <!--                                        Adres-->
                                <!--                                    </div>-->
                            </div>
                            <div>
                                <div class="text-[#000] font-medium pl-[36.5px] h-[32px]">
                                    {{user.email ? user.email : '-'}}
                                </div>
                                <div class="text-[#000] font-medium pl-[36.5px] h-[32px] my-[26px]">
                                    {{user.phone ? user.phone : '-'}}
                                </div>
                                <!--                                    <div class="text-[#000] font-medium pl-[36.5px] h-[32px]">-->
                                <!--                                        Yunusobod-->
                                <!--                                    </div>-->
                            </div>
                        </div>
                    </div>
                </div>

                <div class="my-sale mt-[20px] profile-tables">
                    <div v-if="spinner" class="uk-container mx-auto text-center">
                        <div uk-spinner="ratio: 3"></div>
                    </div>
                    <table v-if="!spinner" class="table table-borderless">
                        <thead>
                        <tr class="text-center">
                            <th>#</th>
                            <th>{{__("application_number")}}</th>
                            <th>{{__("date_of_application")}}</th>
                            <th>{{__("status")}}</th>
                            <th class="action-column">{{__("actions")}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in requests" :key="index" class="text-center tr-th"
                            data-key="85653">
                            <td>{{index + 1}}</td>
                            <td>{{ '#' + item.request_number }}</td>
                            <td>{{datetime(item.created_time)}}</td>
    <!--                                <td>{{'10 000 uzs'}}</td>-->
                            <td>
                                <span class="badge badge-warning">
                                    {{item.statuses_name}}
                                </span>
                            </td>
                            <td>
                                <div class="flex justify-center">
                                    <a href="javascript:void(0)"
                                        class="td bg-[#614CA7] text-[#fff] p-[7px] rounded-[7px]
                                            hover:text-[#fff] max-h-[44px]"
                                            uk-toggle="target: #modal-close-default"
                                    >
                                        {{__("more")}}
                                    </a>
                                </div>
                            </td>
                            <!-- modal -->
                            <div id="modal-close-default" uk-modal>
                                <div class="uk-modal-dialog uk-modal-body">
                                    <button class="uk-modal-close-default" type="button" uk-close></button>
                                    <h2 class="uk-modal-title text-[#614CA7] font-bold mb-4 p-1">
                                        {{__("application_number")}}:  
                                        <span class="text-[#000000] font-medium"> {{item.request_number}}</span>
                                    </h2>
                                    <p class="text-xl p-1 text-[#614CA7] font-medium mb-4">
                                        {{__("date_of_application")}}: 
                                        <span class="text-[#000001] font-normal">{{datetime(item.created_time)}}</span>
                                    </p>
                                    <p class="text-xl p-1 text-[#614CA7] font-medium mb-4">{{__("status")}}: 
                                        <span class="text-[#000001] font-normal">
                                            {{item.statuses_name}}
                                        </span>
                                    </p>
                                    <p class="text-xl p-1 text-[#614CA7] font-medium mb-4">{{__("answer")}}: 
                                        <span class="block uk-box-shadow-medium mt-2 border-2 border-white px-3 py-4 text-[#000001] font-medium uk-text-justify">
                                            {{item.answer_text}}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <!-- /modal -->
                        </tr>
                        </tbody>
                    </table>
                    <div class="uk-card uk-card-default uk-box-shadow-large rounded-[15px]
                                mb-[10px] w-[980px]"
                            v-for="item in 5" :key="item">
                    </div>
                </div>

                <div class="meta-data max-w-[988px] mt-[20px]">
                    <div class="w-[988px]"
                    >
                        <div class="uk-grid mb-4" style="flex-wrap: nowrap;">
                            <div class="flex justify-start space-x-2">
                                <label for="object_type"></label>
                                <select v-model="selected" @change="onChange($event)" class=" mt-1 px-4 bg-[#F5F7FB] border shadow-sm border-[#E1DEF0]
                        relative min-h-[38px] md:min-h-[51px] transition duration-150
                        placeholder-[#393939] placeholder focus:outline-none focus:border-[#614CA7]
                        focus:ring-[#614CA7] block w-full md:w-[247px] rounded-[5px] md:text-[18px] focus:ring-1 uk-cards"
                                        style="padding-right: 2px">
                                    <option value="0">{{__("all_of_them")}}</option>
                                    <option v-for="(option, index) in organizations" v-bind:value="option.id"
                                            :key="index">
                                        {{ option.name }}
                                    </option>
                                </select>
                            </div>

                            <div class="end-placing flex justify-end relative xl:ml-[25%] w-[380px]">
                                <input class="mt-1 px-4 pr-8 md:w-[360px] w-[380px] border shadow-sm border-[#E1DEF0]
                        relative min-h-[38px] md:min-h-[51px] transition duration-150
                        placeholder-[#614CA7] placeholder focus:outline-none focus:border-[#614CA7]
                        focus:ring-[#614CA7] block w-full rounded-[5px] md:text-[18px] focus:ring-1 uk-cards"
                                type="text" :placeholder='__("search")' v-model="filter.name"/>
                                <button type="submit" class="self-center absolute">
                                    <img class="mr-[10px] hover:fill-[#614CA7]" src="../../assets/search.svg" alt=""
                                            @click="getSources">
                                </button>
                            </div>
                        </div>

                        <div v-if="spinner" class="uk-container mx-auto text-center">
                            <div uk-spinner="ratio: 3"></div>
                        </div>
                        <div v-if="!spinner" class="mt-[30px] grid md:grid-cols-4 grid-cols-1 lg:grid-cols-4 gap-4">
                            <div v-for="(source, index) in sources" :key="index" class="mb-[10px]">
                                <Card :item="source">
                                    <template #img>
                                        <img src="@/assets/meta_file.png" alt="file" width="120" height="120">
                                        <!-- <img v-if="index == 0" src="@/assets/pdf_file.png" alt="" class="max-h-[150px] py-[10px]">
                                        <img v-else-if="index == 1" src="@/assets/doc.png" alt="" class="max-h-[150px] py-[10px]">
                                        <img v-else-if="index == 2" src="@/assets/image.png" alt="" class="max-h-[150px] py-[10px]">
                                        <img v-else-if="index == 3" src="@/assets/xls.png" alt="" class="max-h-[150px] py-[10px]">
                                        <img v-else-if="index == 4" src="@/assets/pdf_file.png" alt="" class="max-h-[150px] py-[10px]">
                                        <img v-else src="@/assets/pdf_file.png" alt="" class="max-h-[150px] py-[10px]"> -->
                                    </template>
                                    <template #title>
                                        <p class="font-bold text-[17px] mx-1">{{ source.name }}</p>
                                    </template>

                                    <template #modal_title>
                                        <p class="font-bold my-2 text-[20px] text-[#614CA7]"> {{__("name")}}: <span class="text-[#000000] font-medium mx-2"> {{ source.name }}</span></p>
                                    </template>
                                    <template #object_name>
                                        <p class="font-bold my-2 text-[20px] text-[#614CA7]"> {{__("object_name")}}: <span class="text-[#000000] font-medium mx-2">{{ source.object_name }}</span></p>
                                    </template>
                                    <template #titul> 
                                        <p class="font-bold my-2 text-[20px] text-[#614CA7]"> {{__("title_number")}}: <span class="text-[#000000] font-medium mx-2">{{ source.title_number }}</span></p>
                                    </template>
                                    <template #masshtab>
                                        <p class="font-bold my-2 text-[20px] text-[#614CA7]"> {{__("masshtab")}}: <span class="text-[#000000] font-medium mx-2">{{ source.masshtab }}</span></p>
                                    </template>
                                    <template #nomenklatura>
                                        <p class="font-bold my-2 text-[20px] text-[#614CA7]"> {{__("nomenclature")}}:  <span class="text-[#000000] font-medium mx-2">{{ source.nomenclature }}</span></p>
                                    </template>
                                    <template #format_type>
                                        <p class="font-bold my-2 text-[20px] text-[#614CA7]"> {{__("format_type")}}:  <span class="text-[#000000] font-medium mx-2">{{ source.format_type }}</span></p>
                                    </template>
                                    <template #maker_organizations>
                                        <p class="font-bold my-2 text-[20px] text-[#614CA7]"> {{__("maker_organizations")}}:  <span class="text-[#000000] font-medium mx-2">{{ source.maker_organizations }}</span></p>
                                    </template>
                                    <template #year_of_material>
                                        <p class="font-bold my-2 text-[20px] text-[#614CA7]"> {{__("year_of_material")}}:  <span class="text-[#000000] font-medium mx-2">{{ source.year_of_material }}</span></p>
                                    </template>
                                    <template #regions_names>
                                        <p class="font-bold my-2 text-[20px] text-[#614CA7]"> {{__("regions_names")}}:  <span class="text-[#000000] font-medium mx-2">{{ source.regions_names }}</span></p>
                                    </template>
                                    <template #income_to_dgkf>
                                        <p class="font-bold my-2 text-[20px] text-[#614CA7]"> {{__("income_to_dgkf")}}:  <span class="text-[#000000] font-medium mx-2">{{ source.income_to_dgkf }}</span></p>
                                    </template>
                                </Card>
                            </div>
                        </div>

                        <div class="">
                            <div class="bg-white"
                                style="padding-bottom: 10px; padding-right: 10px; border-radius: 20px;">
                                <div class="max-w-lg mx-auto mt-4">
                                    <PaginationV2 :total-pages="pagination.totalPages" :total="pagination.total"
                                                    :per-page="pagination.perPage"
                                                    :current-page="pagination.currentPage"
                                                    :has-more-pages="pagination.hasMorePages" @pagechanged="showMore"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
</template>

<script>
    import axios from "axios";
    import {apiUrls} from "@/utils/const/apiUrls";
    import PaginationV2 from "@/components/Block/PaginationV2";
    import mixin from "@/boot/boot-mixin"
    import {mapMutations} from "vuex";
    import {mapGetters} from 'vuex'
    import Card from "@/components/Block/Card";

    export default {
        name: "CabinetSection",
        components: {Card, PaginationV2},
        mixins: [mixin],
        data() {
            return {
                selected: 0,
                sources: [],
                organizations: [],
                requests: [],
                start: 1,
                row_length: 0,
                user: {},
                first_name: '',
                last_name: '',
                filter: {
                    name: '',
                },
                pagination: {
                    page: 1,
                    totalPages: 5,
                    total: 40,
                    perPage: 9,
                    currentPage: 1,
                    hasMorePages: true
                },
                spinner: true
            }
        },

        methods: {
            ...mapMutations([
                'addStore'
            ]),
            ...mapGetters([
                'getSIMetaData', 'getUser'
            ]),

            addBasket(item) {
                this.addStore(item);
            },

            logout() {
                this.$store.dispatch("logout").then(() => {
                    this.$router.replace("/")
                });
            },

            showMore(page) {
                this.pagination.page = page;
                this.pagination.currentPage = page;
                this.getSources()
            },

            onChange(event) {
                if (event.target.value != 0) {
                    this.filter.organizations_id = Number(event.target.value)
                } else {
                    delete this.filter.organizations_id
                }

                if (this.filter.name == '') {
                    delete this.filter.name
                }
                this.getSources()
            },

            getFilters() {
                let page = this.pagination
                let pagination = {
                    currPage: page.page,
                    size: page.perPage,
                    descending: page.descending,
                    order_by_: page.sortBy
                }
                let filter = this.filter;
                for (const [key, value] of Object.entries(filter)) {
                    if (value != null && value != undefined) {
                        filter[key] = value
                    }
                }
                for (const [key, value] of Object.entries(pagination)) {
                    if (value != null && value != undefined) {
                        filter[key] = value
                    }
                }
                return filter;
            },


            getSources() {
                let filter = this.getFilters();
                axios.get(apiUrls.SOURCES + this.optionPage(filter))
                    .then(res => {
                        this.sources = res.data.data;
                        this.pagination.total = res.data.total;
                        this.pagination.totalPages = Math.ceil(res.data.total / this.pagination.perPage) + 1
                        this.row_length += res.data.data.length;
                        // console.log(this.sources)
                        this.spinner = false
                    })
                    .catch(function (error) {
                        // console.log(apiUrls.SOURCES)
                        console.log(error)
                    })
                    .finally(() => this.spinner = false)
            },

            getRequest() {
                axios.get(apiUrls.REQUESTS + "?" + this.setParams({'users_id': this.getUser().id, 'language': localStorage.getItem('lang')}))
                    .then(res => {
                        this.requests = res.data.data;
                        // console.log(this.requests);
                        this.spinner = false
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
                    .finally(() => this.spinner = false)
            },

            getOrganizations() {
                axios.get(apiUrls.ORGANIZATIONS + "?" + this.setParams({'language': localStorage.getItem('lang')}))
                    .then(res => {
                        this.organizations = res.data.data;
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },

            storeUser() {
                return this.$store.getters.getUser;
            },
        },
        mounted() {
            this.getSources()
            this.getRequest()
            this.getOrganizations()
            this.user = this.storeUser()
            this.first_name = this.user.fio.toString().split(' ')[1]
            this.last_name = this.user.fio.toString().split(' ')[0]
        }
    }
</script>

<style scoped>

    .min-h-screen {
        min-height: 100vh;
    }

    .bg-gray-100 {
        background-color: #f7fafc;
    }

    .bg-blue-500 {
        background-color: #4299e1;
    }

    .bg-blue-500 {
        background-color: #4299e1;
    }

    .mx-auto {
        margin-left: auto;
        margin-right: auto;
    }

    .my-12 {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    .uk-active {
        color: #6848D2 !important;
        background-color: #F7F7FF;
    }

    .table {
        width: 100%;
        margin-bottom: 1rem;
        color: #212529;
    }

    .text-center {
        text-align: center !important;
    }

    .profile-tables {
        background-color: #fff;
        border-radius: 20px;
        margin-top: 30px;
        box-shadow: 0px 10px 30px rgb(209 213 223/50%);
    }

    .profile-tables table .badge.badge-warning {
        background-color: orange;
    }

    .profile-tables table .badge {
        padding: 4px 6px;
        font-size: 12px;
        color: #fff;
        border-radius: 5px;
        font-weight: 600;
    }

    th {
        padding: 20px;
    }

    td {
        padding: 5px;
    }

    tr {
        padding-bottom: 5px;
    }
</style>