<template>
    <div class="text-[#000] text-[20px] pb-[27.5px]  relative font-bold">
        <slot name="name"></slot>
    </div>
</template>

<script>
    export default {
        name: "HeaderCheck"
    }
</script>

<style scoped>

</style>