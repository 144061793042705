<template>
    <div>
        <Navbar :publications="publications"/>
        <Container class="mb-[100px] relative">
            <Breadcrumbs>
                <template #link>
                    <a class="text-[#000] font-medium hover:no-underline hover:text-[#614CA7]"
                       href="javascript:void(0);">
                        {{ __("physical_person") }}
                    </a>
                </template>
            </Breadcrumbs>
            <div>
                <div class="text-[18px] text-[#614CA7] font-extrabold leading-[22px] uppercase mt-[36px] relative dkg-bottom-line">
                    {{ __("physical_person") }}
                </div>

                <div class="mt-4 uk-text-justify" v-html="menu_child[0].content">

                </div>
            </div>
        </Container>
        <!-- <Table/> -->
        <Footer :static_info="static_info" :cur_lang="cur_lang"/>
    </div>
</template>

<script>
    import Navbar from "@/components/Layouts/Navbar";
    import Container from "@/components/Block/Container";
    import Breadcrumbs from "@/components/Block/Breadcrumbs";
    import Footer from "@/components/Layouts/Footer";
    // import Table from "@/components/Layouts/Table";
    import axios from "axios";
    import {apiUrls} from "@/utils/const/apiUrls";
    import mixin from "@/boot/boot-mixin";
    import {defineComponent} from "vue";

    export default defineComponent({
        // eslint-disable-next-line vue/multi-word-component-names
        name: "TablePage",
        components: {Navbar, Footer, Container, Breadcrumbs},
        mixins: [mixin],
        props: {
            id: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                publications: [],
                menu_child: [],
                static_info: new Map(),
                cur_lang: localStorage.getItem('lang')
            }
        },
        created() {
            axios.get(apiUrls.PUBLICATIONS + "?" + this.setParams({'language': localStorage.getItem('lang')}))
                .then(response => {
                    this.publications = response.data.data;
                }).catch(error => {
                console.log(error);
            }).finally(() => {
            });

            axios.get(apiUrls.SYSTEM_SETTINGS + "?" + this.setParams({'language': localStorage.getItem('lang')}))
                .then(response => {
                    for (let i = 0; i < response.data.total; i++) {
                        this.static_info.set(response.data.data[i].name, response.data.data[i].value)
                    }
                }).catch(error => {
                console.log(error);
            }).finally(() => {
            });

            axios.get(apiUrls.SITE_MENUS + "?" + this.setParams({
                'language': localStorage.getItem('lang'),
                'id': this.id
            }))
                .then(response => {
                    this.menu_child = response.data.data;
                }).catch(error => {
                console.log(error)
            }).finally(() => {
            });
        },
    })
</script>

<style scoped>
    .heading-section {
        background-image: url('../../assets/heading.png') !important;
        object-fit: cover;
        position: relative;
        z-index: 10;
        background: no-repeat;
    }

    .header-card::after {
        content: '';
        position: absolute;
        display: block;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: #DFDFDF;
        width: 2px;
        height: 70px;
    }

    .header-card:last-child::after {
        display: none;
    }

    .heading-section::after {
        content: '';
        position: absolute;
        z-index: -1;
        opacity: .57;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }

    .heading-title span {
        font-weight: 900;
    }

</style>