<template>
  <div class="flex-col justify-center items-center relative">
    <div>
      <div class="card__image">
        <slot name="img"></slot>
      </div>
    </div>
    <div class="w-[100%] absolute top-47p">
      <div class="bg-white mx-4 rounded-[5px]">
        <div>
          <div class="card__tag fs-17 text-[#2E2E2E] font-semibold text-center leading-[20px] mx-8 pt-4">
            <slot name="tag"></slot>
          </div>
        </div>
        <div>
          <div class="card__content fs-16 text-[#2E2E2E] font-regular text-center leading-[19px] mx-8 pt-6">
            <slot name="content"></slot>
          </div>
        </div>
      </div>
    </div>
    <div class="card__button font-semibold flex-row justify-center items-center">
      <slot name="button"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsCard"
}
</script>

<style scoped>

.card__content{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

}

.card__button {
  background-color: #F5F7FB;
  border: 1px solid #EFF0F8;
  border-radius: 7px;
  font-size: 17px;
  color: #614CA7;
  padding: 8px 15px;
  align-content: center;
  text-align: center;
  margin-right: 80px;
  margin-left: 80px;
}

.card__button:hover {
  background-color: #614CA7;
  border: 1px solid #614CA7;
  color: white;
  font-size: 17px;
  transition: all .4s;
  cursor: pointer;
}

.card__image {
  border-radius: 5px 4px 0px 0px;
  position: relative;
  object-fit: cover;
  margin-bottom: 40%;
  min-height: 242px !important;
}

.fs-17 {
  font-size: 17px;
}

.fs-16 {
  font-size: 16px;
}

.top-47p {
  top: 47%;
}


@media (min-width: 600px) and (max-width: 1023px) {
  .fs-17 {
    font-size: 15px;
  }

  .fs-16 {
    font-size: 14px;
  }

  .top-47p {
    top: 45%;
  }
  .card__button{
    margin-right: 120px;
    margin-left: 120px;
    margin-top: -100px;
  }
}

@media (max-width: 599px) {
  .fs-17 {
    font-size: 13px;
  }

  .fs-16 {
    font-size: 12px;
  }

  .top-47p {
    top: 40%;
  }
  .card__button{
    margin-top: 100px;
  }
}
</style>
