<template>
  <div class="relative heading flex items-left justify-left w-full">
    <span class="self-center mb-[39PX] font-extrabold text-[#614CA7] md:text-[23px] text-[18px] uppercase">
      <slot name="title"></slot>
    </span>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Heading"
}
</script>

<style scoped>
.heading {
  text-align: start;
}
.heading::after {
  content: '';
  position: absolute;
  right: -43.5%;
  top: 25%;
  transform: translateX(-50%);
  background-color: #C9C2E1;
  height: 1px;
  width:86.5%;
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .heading::after {
    right: -40%;
    width:80%;
  }
}


@media (min-width: 600px)  and (max-width: 1023px) {
  .heading::after {
    right: -35%;
    width:72%;
  }
}

@media (max-width: 599px) {
  .heading::after {
    width:0
  }
}
</style>
