<template>
  <Navbar :publications="publications"/>
  <Container class="mb-[100px]">
    <Breadcrumbs>
      <template #link>
        <a class="text-[#000] font-medium hover:no-underline hover:text-[#614CA7]" href="javascript:void(0);">
          {{ __('news') }}
        </a>
      </template>
    </Breadcrumbs>
    <div class="uk-grid mt-[30px]">
      <div class="w-63p" v-for="public_new in public_news" :key="public_new">
        <div class="fs-18 text-[#614CA7] font-extrabold leading-[22px] uppercase relative big-bottom-line">
          <div>{{ public_new.header }}</div>
        </div>
        <div class="mt-[30px]">
          <img class="w-[844.51px] h-[415.77px] object-cover rounded-[7px]" alt="earth"
               :src="ImgPath(public_new.resources_id)"
               uk-img="target: #news">
        </div>
        <div class="fs-16 text-[#2E2E2E] font-regular leading-[21px] mt-[27px]">
          <div v-html="public_new.short_content"></div>
        </div>
        <div class="fs-14 text-[#B2B2B2] font-regular leading-[18px] mt-[35px]">
          {{ setDateWithTime(public_new.created_date) }}
        </div>
      </div>

      <div class="w-37p">
        <div class="relative small-bottom-line">
          <button
              class="px-[25px] py-[9px] rounded-[7px] bg-[#F5F7FB] text-[#614CA7] fs18 font-extrabold hover:bg-[#614CA7] hover:text-white uppercase ease-in duration-300">
            {{ __('last_news') }}
          </button>
        </div>
        <div class="space-y-8 mt-[30px]">
          <div class="flex relative small-bottom-line-a cursor-pointer" v-for="(pub, index) in publications"
               :key="index" @click.stop="toNewsPage(pub)">
            <img alt="" v-if="pub.resources_id != null" :src="ImgPath(pub.resources_id)"
                 uk-img="target: #news"
                 class="w-[183px] h-[126px] rounded-[7px] object-cover">
            <img alt="" v-else src="../../assets/earth.png" uk-img="target: #news"
                 class="w-[183px] h-[126px] rounded-[7px] object-cover">
            <div class="ml-[24px]">
              <div class="fs-16 text-[#2E2E2E] font-semibold leading-[19px] mt-2">
                {{ pub.header }}
              </div>
              <div class="fs-14 text-[#B2B2B2] font-regular leading-[18px] mt-6">
                {{ setDateWithTime(pub.created_date) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
  <Footer :static_info="static_info" :cur_lang="cur_lang"/>
</template>

<script>

import Container from "@/components/Block/Container";
import Breadcrumbs from "@/components/Block/Breadcrumbs";
import Navbar from "@/components/Layouts/Navbar";
import Footer from "@/components/Layouts/Footer";
import axios from "axios";
import {apiUrls, baseUrl} from "@/utils/const/apiUrls";
import mixin from "@/boot/boot-mixin";

export default {
  name: "NewsPage",
  components: {Container, Breadcrumbs, Navbar, Footer},
  mixins: [mixin],
  props: {
    id: {
      Number
    }
  },
  data() {
    return {
      publications: [],
      public_news: [],
      system_settings: [],
      static_info: new Map(),
      cur_lang: localStorage.getItem('lang'),
      toggle_news: false
    }
  },
  created() {
    axios.get(apiUrls.SYSTEM_SETTINGS + "?" + this.setParams({'language': localStorage.getItem('lang')}))
        .then(response => {
          this.system_settings = response.data.data;
          for (let i = 0; i < response.data.total; i++) {
            this.static_info.set(response.data.data[i].name, response.data.data[i].value)
          }
        }).catch(error => {
      console.log(error);
      console.log(error)
    }).finally(() => {
    });
  },
  methods: {
    getPublications() {
      let filter = {}
      axios.get(apiUrls.PUBLICATIONS, filter)
          .then(res => {
            this.publications = res.data.data;
            // console.log("get", this.publications);
          })
          .catch(function (error) {
            // console.log(apiUrls.PUBLICATIONS)
            console.log(error)
          })
    },

    getPublicNews(id) {
      axios.get(apiUrls.PUBLICATIONS + "?" + this.setParams({'id': id}))
          .then(res => {
            this.public_news = res.data.data;
            // console.log("id", id);
            // console.log("create", this.public_news);
          })
          .catch(function (error) {
            // console.log(apiUrls.PUBLICATIONS)
            console.log(error)
          });
    },

    toNewsPage(row) {
      this.getPublicNews(row.id);
      this.$router.push({
        name: 'news',
        params: {id: row.id}
      })
    },

    ImgPath(value) {
      if (value === null) {
        return null;
      } else {
        return baseUrl.url + '/resources/' + 'view?resource_id=' + value
      }

    },

    setDateWithTime(value) {
      let val = value * 1
      let date = new Date(val)
      let day = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate();
      let mes = (date.getMonth() + 1) >= 10 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
      let year = date.getFullYear();
      let hour = date.getHours();
      let minutes = date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes();
      let second = date.getSeconds() >= 10 ? date.getSeconds() : '0' + date.getSeconds()
      return day + '.' + mes + '.' + year + '  ' + hour + ':' + minutes + ':' + second
    }
  },
  mounted() {
    this.getPublications();
    this.getPublicNews(this.id)
    // console.log(this.id);
  }
}
</script>

<style scoped>
.big-bottom-line::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #DEDEDE;
  right: 0;
  bottom: -30%;
}

.small-bottom-line::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #DEDEDE;
  right: 0;
  bottom: -30%;
}

.small-bottom-line-a::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #DEDEDE;
  right: 0;
  bottom: -13%;
}

.w-63p {
  width: 63%;
}

.w-37p {
  width: 37%;
}

.fs-18 {
  font-size: 18px;
}
.fs-16 {
  font-size: 16px;
}
.fs-14 {
  font-size: 14px;
}
@media (min-width: 600px) and (max-width: 1023px) {
  .w-63p {
    width: 100%;
  }

  .w-37p {
    width: 100%;
  }
  .fs-18 {
    font-size: 16px;
  }
  .fs-16 {
    font-size: 14px;
  }
  .fs-14 {
    font-size: 12px;
  }
}
@media (max-width: 599px) {
  .w-63p {
    width: 100%;
  }

  .w-37p {
    width: 100%;
  }
  .fs-18 {
    font-size: 14px;
  }
  .fs-16 {
    font-size: 12px;
  }
  .fs-14 {
    font-size: 10px;
  }

}
</style>
