import {createRouter, createWebHashHistory} from 'vue-router'
import Home from '../components/Home/Index';
import TablePage from '../components/Pages/TablePage';
import TablePageSecond from '../components/Pages/TablePageSecond';
import TablePageThird from '../components/Pages/TablePageThird';
import NewsPage from '../components/Pages/NewsPage';
import WorkersPage from '../components/Pages/WorkersPage';
import DkgPage from '../components/Pages/DkgPage';
import Departaments from '../components/Pages/Departaments';
// import Documents from '../components/Pages/Documents';
import Cabinet from "../components/Cabinet/Cabinet";
import Checkout from "@/components/Pages/Checkout";

const routes = [
    {
        path: '/', name: 'home', component: Home
    },
    {
        path: '/individuals/:id', name: "individuals", component: TablePage, props: true
    },
    // {
    //     path: '/entities/:id', name: "entities", component: LegalTablePage, props: true
    // },
    {
        path: '/entities/:id', name: "entities", component: TablePageSecond, props: true
    },
    {
        path: '/news/:id', name: "news", component: NewsPage, props: true
    },
    {
        path: '/workers', name: "workers", component: WorkersPage
    },
    {
        path: '/cabinet', name: "cabinet", component: Cabinet
    },
    {
        path: '/dkgf', name: "dkgf", component: DkgPage
    },
    {
        path: '/departaments', name: "departaments", component: Departaments
    },
    {
        path: '/documents/:id', name: "documents", component: TablePageThird, props: true
    },
    {
        path: '/checkout', name: "checkout", component: Checkout
    },

]

const router = createRouter({
    history: createWebHashHistory(), // history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
