<template>
    <div>
        <label class="text-slate-700 font-medium uk-cards uk-cards" :for="name">{{ title }}</label>
        <input :id="name"
               :name="name"
               :type="type"
               class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300
                        relative min-h-[38px] md:min-h-[48px] transition duration-150
                        placeholder-slate-400 focus:outline-none focus:border-[#614CA7]
                        focus:ring-[#614CA7] block w-full rounded-md md:text-[18px] focus:ring-1 uk-cards"
               :placeholder="placeholder"
               :value="modelValue"
               @input="$emit('update:modelValue', $event.target.value)"
               ref="input"
        >
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Input",
    props: {
        type: String,
        name: String,
        title: String,
        placeholder: String,
        'modelValue': Array,
    },
    methods: {
        focus() {
            this.$refs.input.focus()
        }
    }
}
</script>

<style scoped>

label:after {
    content: '*';
    color: red;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

</style>