import { createApp } from 'vue'
import App from './App.vue'
import Vue3Autocounter from 'vue3-autocounter';
import router from './router'
import store from './store/store'

import OpenLayersMap from 'vue3-openlayers'
import 'vue3-openlayers/dist/vue3-openlayers.css'


createApp(App)
    .use(store)
    .use(OpenLayersMap)
    .use(router).mixin(require('../base')).component('vue3-autocounter', Vue3Autocounter).mount('#app')
