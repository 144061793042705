<template>
  <div class="bg-[#F5F7FB]">
    <div class="uk-container">
      <div class="uk-grid uk-child-width-1-2@l uk-child-width-1-2@m uk-child-width-1-1@s">
        <div class="flex mt-[45px] mb-[38px] space-x-[45px]" v-for="(s, index) in services" :key="index">
          <img :src="require(`../../assets${s.icon}`)" alt="">
          <div class="self-center space-y-1">
            <div class="md:text-[17px] text-[#000000] font-semibold leading-[20px]">
              {{ s.header }}
            </div>
            <div class="md:text-[16px] text-[#3B4256] font-regular leading-[19px]">
              {{ s.short_content }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {apiUrls} from "@/utils/const/apiUrls";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Service",
  data() {
    return {
      services: [],
    }
  },
  methods: {
    getServices() {
      let filter = {}
      axios.get(apiUrls.SERVICES, filter)
          .then(res => {
            this.services = res.data.data;
            this.services.length = 2;
            // console.log("get", this.services);
          })
          .catch(function (error) {
            // console.log(apiUrls.SERVICES)
            console.log(error)
          })
    },
  },
  mounted() {
    this.getServices()
  }
}
</script>

<style scoped>
.right-line::before {
  content: '';
  position: absolute;
  height: 54px;
  width: 1px;
  margin-left: -45px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #E2E2E2;
}
</style>
