import createPersistedState from "vuex-persistedstate";
import {createStore} from "vuex";

const store = createStore({
    state() {
        return {
            gOnStoreItems: [],
            gOnStoreCards: [],
            auth: {},
        }
    },
    mutations: {
        addStore(state, item) {
            if (state.gOnStoreItems.filter(col => col.id == item.id).length == 0) {
                state.gOnStoreItems.push(item);
            }
        },

        removeStore(state, item) {
            if (state.gOnStoreItems?.includes(item)) {
                state.gOnStoreItems = state.gOnStoreItems?.filter(obj => obj !== item)
            }
        },
        clearStoreItems(state, data) {
            console.log(data)
            state.gOnStoreItems = []
        },
        minusCountStore(state, item) {
            if (state.gOnStoreItems.find(obj => obj === item).count > 1) {
                state.gOnStoreItems.find(obj => obj === item).count -= 1;
            }
        },
        login(state, item) {
            state.auth = item
        },
        logout(state) {
            state.auth = {};
        }
    },
    actions: {
        addStore(state, item) {
            state.commit("addStore", item)
        },
        removeStore(state, item) {
            state.commit("removeStore", item)
        },
        login(state, item) {
            state.commit("login", item)
        },
        logout(state) {
            state.commit("logout")
        },

    },
    getters: {
        getUser(state) {
            return state.auth
        },
        getSIMetaData(state) {
            return state.gOnStoreItems
        }
    },
    plugins: [createPersistedState()]
});

export default store;