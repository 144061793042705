<template>
    <Section id="forms" class="bg-[#fff]">
        <Container>
            <Heading class="md:mt-12 lg:mt-12">
                <template #title>
                    {{__("submit")}}
                </template>
            </Heading>
            <!--            <form>-->
            <Grid class="uk-grid-medium uk-child-width-1-2@m relative"
                  uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: .uk-cards; delay: 80; repeat: true">
<!--                <Input v-model="sectors" name="sectors" :placeholder='__("depatment")' :title='__("depatment")'-->
<!--                       type="text"/>-->
                <Input v-model="fio" name="fio" :placeholder='__("fish")' :title='__("fish")' type="text"/>
<!--                <Input v-model="passport" name="passport" :placeholder='__("passport")' :title='__("passport")'-->
<!--                       type="text"/>-->
                <Input v-model="email" name="email" :placeholder='__("email")' :title='__("email")' type="email"/>
                <Input v-model="phone" name="phone" :placeholder='__("phone")' :title='__("phone")' type="number"/>
<!--                <Input v-model="number" name="number" :placeholder='__("count")' :title='__("count")' type="number"/>-->
                <Input v-model="ask_type" name="ask_type" :placeholder='__("type_data")' :title='__("type_data")'
                       type="text"/>
                <div class="flex flex-col text-bold w-full">
                    <div>
                        <a v-if="storeUser.id" class="button" href="#/cabinet">{{__("submit")}}</a>
                        <a v-else class="button" :href="oneIDUri">{{__("submit")}}</a>
                    </div>
                </div>
            </Grid>
            <!--            </form>-->
        </Container>
    </Section>
</template>

<script>
    import Grid from "@/components/Block/Grid";
    import Heading from "@/components/Layouts/Heading";
    import Container from "@/components/Block/Container";
    import Section from "@/components/Block/Section";
    import Input from "@/components/Block/Input";
    import axios from "axios";
    import {apiUrls} from "@/utils/const/apiUrls";

    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: "FormInput",
        components: {Input, Section, Container, Heading, Grid},

        data() {
            return {
                sectors: null,
                fio: null,
                passport: null,
                email: null,
                phone: null,
                number: null,
                ask_type: null,
                ask_type1: null,
                ask_type2: null,
                response_data: null,

                clientId: 'online-auksion_uz',
                clientSecret: 'Mpy4Zx84UCPdORSTNcnW89sO',
                state: 'Mpy4Zx84UCPdORSTNcnW89sO',
                oneIdUri: 'https://sso.egov.uz/sso/oauth/Authorization.do',
                target_self: '',
                code: window.location.search ? window.location.search.replace('?code=', '') : '',
                redirect_uri: location.origin.includes('localhost') ? 'http://localhost:8080/' : 'https://geofond.kadastr.uz/',
            }
        },

        computed: {
            oneIDUri() {
                return this.oneIdUri + '?response_type=one_code&client_id=' + this.clientId
                    + '&redirect_uri=' + this.redirect_uri + '&scope=' + this.clientId + '&state=' + this.state
            },
            storeUser() {
                return this.$store.getters.getUser;
            }
        },

        methods: {

            toCabinet() {
                let href = window.location.href;
                href = href.split('?code=');
                window.location.href = href[0] + '#cabinet';
            },

            // getToken() {
            //     axios.post(this.oneIdUri + '?grant_type=one_authorization_code&' +
            //         'client_id=' + this.clientId + '&client_secret=' + this.clientSecret + '&code=' + this.code +
            //         '&redirect_uri=' + this.redirect_uri)
            //         .then(res => {
            //             this.getData(res.data.access_token)
            //         })
            // },
            // getData(token) {
            //     axios.post(this.oneIdUri + '?grant_type=one_access_token_identify&' +
            //         'client_id=' + this.clientId + '&client_secret=' + this.clientSecret +
            //         '&access_token=' + token + '&scope=' + this.code)
            //         .then(res => {
            //             this.oauth(res.data)
            //         })
            // },
            oauth(data) {
                axios.post(apiUrls.ONE_ID_OAUTH2, {code: data}).then(res => {
                    this.$store.dispatch("login", res.data).then(() => {
                            this.toCabinet()
                        }
                    )

                })
            },
        },
        mounted() {
            let user = this.storeUser
            if (this.code && !user.id) {
                // this.oauth(this.code.split('&state')[0])
            }
        }
    }
</script>

<style scoped>
    .submit-img:hover img {
        content: url('../../assets/message.png');
        transition: all .4s;
    }

    .button {
        display: block;
        width: 200px;
        height: 45px;
        background: #614CA7;
        padding: 10px;
        text-align: center;
        border-radius: 5px;
        color: white;
        font-weight: bold;
        line-height: 25px;
        text-decoration-line: none;
    }
</style>
