<template>
    <div>
        <div :class="[open ? 'relative uk-inline opened' : 'relative uk-inline', hasError ? 'error' : '']">
            <Select2 :options="myOptions" :settings="{ settingOption: value, settingOption: value }"
                     @change="myChangeEvent($event)" @select="mySelectEvent($event);$emit('removeErrorFromSelect')"
                     @focusin="open=true"
                     @focusout="open=false"
                     :value="modelValue" @input="$emit('update:modelValue', $event.target.value)"
            >
            </Select2>
            <img src="@/assets/chevron-down.svg" alt="" class="absolute top-[24px] right-[24px]">
        </div>
    </div>
</template>

<script>
import Select2 from 'vue3-select2-component';

export default {
    name: `Select`,
    components: {Select2},
    props: {
        'modelValue': Array,
        options: Array,
        hasError: Boolean
    },
    data() {
        return {
            myOptions: this.options ?? [],
            open: false
            // or [{id: key, text: value}, {id: key, text: value}]
        }
    },
    methods: {
        myChangeEvent(val) {
            console.log(val);
        },
        mySelectEvent({id, text}) {
            console.log({id, text})

        }
    }
}
</script>

<style>
.select2-container {
    width: 402px !important;
}

.select2-selection {
    height: 56px !important;
    display: flex !important;
    align-items: center !important;
    padding: 18px !important;
    border: 1px solid #C3B2FF !important;
    border-radius: 8px !important;
}
.error .select2-selection{
    border: 1px solid #f00 !important
}

.opened .select2-selection {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.select2-selection__arrow {
    display: none !important;
}

.select2-dropdown {
    border: 1px solid #C3B2FF !important;
    border-top: 0px !important;
    padding: 12px 3px 12px 14.5px !important;
}

.select2-search__field {
    background-color: #FAF9FE !important;
    border: 1px solid #C3B2FF !important;
    border-radius: 8px !important;
}

.select2-search__field:focus {
    outline: none !important;
    color: #000 !important;
}

.select2-selection__rendered {
    color: #000 !important;
    font-size: 15px !important;
    font-weight: 500 !important;
}

.select2-results__option {
    color: #000 !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    padding: 13px 0px 12px 15.5px !important;
    border-bottom: 1px solid #C3B2FF !important;
    background-color: #fff !important;
}

.select2-results__option:hover {
    background-color: #FAF9FE !important;
    transition: all .3s !important;
}

.select2-results__options {
    padding-right: 4px;
}

.select2-results__options::-webkit-scrollbar {
    width: 5px;
}

.select2-results__options::-webkit-scrollbar-track {
    box-shadow: none;
    height: 32px!important;
}

.select2-results__options::-webkit-scrollbar-thumb {
    height: 32px!important;
    background-color: #6949D3;
    border-radius: 99999999px;
}
</style>