<template>
    <div id="statistics" class="bg-[#614CA7] pt-[30px] pb-[65px]">
        <Container>
            <Heading>
                <template #title>
                    <span class="text-white">{{__("Statistics")}}</span>
                </template>
            </Heading>
            <Grid class="uk-grid-medium uk-child-width-1-4@l uk-grid-match uk-child-width-1-2@m">
                <StatisticsCard>
                    <template #content>
                        {{__("consideration")}}
                    </template>
                    <template #number>
                        <vue3-autocounter ref='counter' :startAmount='0' :endAmount='5478' :duration='4' :autoinit='true'>5478</vue3-autocounter>
                    </template>
                    <template #icon>
                        <img alt="earth" class="text-white" src="../../assets/statistics1.svg" uk-svg>
                    </template>
                </StatisticsCard>
                <StatisticsCard>
                    <template #content>
                        {{__("rejected")}}
                    </template>
                    <template #number>
                        <vue3-autocounter ref='counter' :startAmount='0' :endAmount='1453' :duration='4'>1453</vue3-autocounter>
                    </template>
                    <template #icon>
                        <img alt="earth" class="text-white" src="../../assets/statistics2.svg" uk-svg>
                    </template>
                </StatisticsCard>
                <StatisticsCard>
                    <template #content>
                        {{__("provided")}}
                    </template>
                    <template #number>
                        <vue3-autocounter ref='counter' :startAmount='0' :endAmount='25' :duration='4'>25</vue3-autocounter>
                    </template>
                    <template #icon>
                        <img alt="earth" class="text-white" src="../../assets/statistics3.svg" uk-svg>
                    </template>
                </StatisticsCard>
                <StatisticsCard>
                    <template #content>
                        {{__("rejected")}}
                    </template>
                    <template #number>
                        <vue3-autocounter ref='counter' :startAmount='0' :endAmount='25' :duration='4'>25</vue3-autocounter>
                    </template>
                    <template #icon>
                        <img alt="earth" class="text-white" src="../../assets/statistics3.svg" uk-svg>
                    </template>
                </StatisticsCard>

            </Grid>
        </Container>
    </div>
</template>

<script>
import StatisticsCard from "@/components/Block/StatisticsCard";
import Grid from "@/components/Block/Grid";
import Heading from "@/components/Layouts/Heading";
import Container from "@/components/Block/Container";
import { defineComponent } from 'vue';
import Vue3autocounter from 'vue3-autocounter';

// import Section from "@/components/Block/Section";

export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Statistics",
    components: { Container, Heading, Grid, StatisticsCard, 'vue3-autocounter': Vue3autocounter}
});
</script>
<style scoped>

</style>