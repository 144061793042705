<template>
    <div>
        <Navbar @changeLanguage="changeLanguage"/>

        <CabinetHeader/>
        <CabinetSection/>

        <Footer :static_info="static_info" :cur_lang="cur_lang"/>
    </div>
</template>

<script>
    import Navbar from "@/components/Layouts/Navbar";
    import Footer from "@/components/Layouts/Footer";
    import CabinetHeader from "@/components/Cabinet/CabinetHeader";
    import CabinetSection from "@/components/Cabinet/CabinetSection";
    import axios from "axios";
    import {apiUrls} from "@/utils/const/apiUrls";
    import mixin from "@/boot/boot-mixin";

    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: "Cabinet",
        components: {CabinetSection, CabinetHeader, Footer, Navbar},
        mixins: [mixin],
        data() {
            return {
                system_settings: [],
                static_info: new Map(),
                cur_lang: localStorage.getItem('lang')
            }
        },
        created() {
            axios.get(apiUrls.SYSTEM_SETTINGS + "?" + this.setParams({'language': localStorage.getItem('lang')}))
                .then(response => {
                    this.system_settings = response.data.data;
                    for (let i = 0; i < response.data.total; i++) {
                        this.static_info.set(response.data.data[i].name, response.data.data[i].value)
                    }
                }).catch(error => {
                console.log(error);
                console.log(error)
            }).finally(() => {
            });
        },
        methods: {
            changeLanguage(lang) {
                localStorage.setItem('lang', lang)
                this.active = false;
                this.$nextTick(() => {
                    // Adding the component back in
                    this.active = true;
                });
                window.location.reload()
            }
        }
    }
</script>

<style scoped lang="css">
    /*@import "./src/style/output.css";*/
</style>