<template>
  <div class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-4@s uk-margin uk-padding rounded-2xl shadow-xl" uk-grid>
      <div class="uk-width-1-4@m uk-card-media-left uk-cover-container text-center">
        <div class="rounded-full p-3 bg-[#F4F3FA] inline-block">
          <slot name="img"></slot>
        </div>
      </div>
      <div  class="uk-width-3-4@m">
          <div class="">
              <h3 class="text-[18px] text-[#614CA7] font-bold mb-[15px]">
                <slot name="name"></slot>
              </h3>
              <p class="text-[16px] text-[#434343]/50 font-semibold  mb-[20px]">
                <slot name="specialty"></slot>
              </p>
              <hr class="mr-14">
              
              <div class="uk-text-center mt-[10px]">
                <div class="py-2">
                  <div class="uk-flex">
                    <span class="text-[#000000] text-[14px] font-medium mr-4">Telefon: </span>
                    <span uk-icon="icon: receiver; ratio: 1.2" class="text-[#614CA7] font-bold"></span>
                    <div class="mx-2">
                      <slot name="phone"></slot>
                    </div>
                  </div>
                </div>
                <div class="py-2">
                  <div class="uk-flex">
                    <span class="text-[#000000] text-[14px] font-medium mr-4">Email: </span>
                    <span uk-icon="icon: mail; ratio: 1.2" class="text-[#614CA7] font-bold"></span>
                    <div class="mx-2">
                      <slot name="email"></slot>
                    </div>
                  </div>
                </div>
                <div v-if="work_time != null" class="py-2">
                  <div class="uk-flex">
                    <span class="text-[#000000] text-[14px] font-medium mr-4">Ish vaqti: </span>
                    <span uk-icon="icon: clock; ratio: 1.2" class="text-[#614CA7] font-bold"></span>
                    <div class="mx-2">
                      <slot name="work_time"></slot>
                    </div>
                  </div>
                </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "WorkersCard",
  props: ['work_time'],
}
</script>

<style>
  @media (min-width: 1200px){
    .uk-card-body {
        padding: 20px 10px !important;
    }
  }
</style>