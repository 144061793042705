<template>
    <div>
        <Navbar :publications="publications"/>
        <Container class="mb-[100px] relative">
            <Breadcrumbs>
                <template #link>
                    <a class="text-[#000] font-medium hover:no-underline hover:text-[#614CA7]"
                       href="javascript:void(0);">
                        {{ __("legal_document") }}
                    </a>
                </template>
            </Breadcrumbs>
            <div>
                <div class="text-[18px] text-[#614CA7] font-extrabold leading-[22px] uppercase mt-[36px] relative dkg-bottom-line">
                    {{ __("legal_document") }}
                </div>

                <div class="mt-4 uk-text-justify" v-html="menu_child[0].content">

                </div>

<!--                <div class="mt-4 uk-text-justify">-->
<!--                    <table style="width: 100%">-->
<!--                        <tbody>-->
<!--                        <tr class="dd-table__main-item">-->
<!--                            <td><span class="dd-table__main-item_number">1</span></td>-->
<!--                            <td>-->
<!--                                        <span class="lx_act_state">-->
<!--                                            <img class="hover:cursor-pointer" src="@/assets/check_mark.png" alt="basket"-->
<!--                                                 style="width: 32px;"/>-->
<!--                                        </span>-->
<!--                                <div class="dd-table__main-left-desc">-->
<!--                                    <a class="lx_link" href="/docs/6307745" target="_blank">Вазирлар Маҳкамасининг 2011-->
<!--                                        йил 16 майдаги 136-сон қарори билан тасдиқланган Хорижий инвестициялар-->
<!--                                        иштирокидаги корхоналар томонидан ўз ишлаб чиқариш эҳтиёжлари учун олиб-->
<!--                                        келинаётган мол-мулкни аниқлаш тартиби тўғрисидаги низомга ўзгартириш ва-->
<!--                                        қўшимчалар киритиш, шунингдек, Ўзбекистон Республикаси Ҳукуматининг айрим-->
<!--                                        қарорларини ўз кучини йўқотган деб ҳисоблаш тўғрисида</a>-->
<!--                                </div>-->
<!--                                <span class="badge badge-pill badge-nine">Ўзбекистон Республикаси Вазирлар Маҳкамасининг қарори , 07.12.2022 йилдаги 696-сон</span>-->
<!--                            </td>-->
<!--                        </tr>-->

<!--                        <tr class="dd-table__main-item">-->
<!--                            <td><span class="dd-table__main-item_number">2</span></td>-->
<!--                            <td>-->
<!--                                        <span class="lx_act_state">-->
<!--                                            <img class="hover:cursor-pointer" src="@/assets/check_mark.png" alt="basket"-->
<!--                                                 style="width: 32px;"/>-->
<!--                                        </span>-->
<!--                                <div class="dd-table__main-left-desc">-->
<!--                                    <a class="lx_link" href="/docs/6307886" target="_blank">Европа тикланиш ва тараққиёт-->
<!--                                        банки иштирокида «Андижон, Наманган ва Фарғона вилоятларида 118 та насос-->
<!--                                        станциясини модернизация қилиш» лойиҳасини амалга ошириш чора-тадбирлари-->
<!--                                        тўғрисида</a>-->
<!--                                </div>-->
<!--                                <span class="badge badge-pill badge-nine">Ўзбекистон Республикаси Президентининг қарори, 07.12.2022 йилдаги ПҚ-438-сон</span>-->
<!--                            </td>-->
<!--                        </tr>-->

<!--                        <tr class="dd-table__main-item">-->
<!--                            <td><span class="dd-table__main-item_number">3</span></td>-->
<!--                            <td>-->
<!--                                        <span class="lx_act_state">-->
<!--                                            <img class="hover:cursor-pointer" src="@/assets/check_mark.png" alt="basket"-->
<!--                                                 style="width: 32px;"/>-->
<!--                                        </span>-->
<!--                                <div class="dd-table__main-left-desc">-->
<!--                                    <a class="lx_link" href="/docs/6307874" target="_blank">Сув хўжалиги соҳасида-->
<!--                                        инвестиция маблағларидан самарали фойдаланиш мақсадида Ўзбекистон Республикаси-->
<!--                                        Президентининг 2015 йил 24 мартдаги «Жанубий Қорақалпоғистонда сув ресурсларини-->
<!--                                        бошқаришни яхшилаш» лойиҳасини Жаҳон банки иштирокида амалга ошириш-->
<!--                                        чора-тадбирлари тўғрисида»ги ПҚ-2324-сон қарорига ўзгартиришлар киритиш-->
<!--                                        ҳақида</a>-->
<!--                                </div>-->
<!--                                <span class="badge badge-pill badge-nine">Ўзбекистон Республикаси Президентининг қарори, 07.12.2022 йилдаги ПҚ-437-сон</span>-->
<!--                            </td>-->
<!--                        </tr>-->
<!--                        </tbody>-->
<!--                    </table>-->
<!--                </div>-->

            </div>
        </Container>
        <!-- <Table/> -->
        <Footer :static_info="static_info" :cur_lang="cur_lang"/>
    </div>
</template>

<script>
    import Navbar from "@/components/Layouts/Navbar";
    import Container from "@/components/Block/Container";
    import Breadcrumbs from "@/components/Block/Breadcrumbs";
    import Footer from "@/components/Layouts/Footer";
    // import Table from "@/components/Layouts/Table";
    import axios from "axios";
    import {apiUrls} from "@/utils/const/apiUrls";
    import mixin from "@/boot/boot-mixin";
    import {defineComponent} from "vue";
    import Img from "@/components/Block/Img";

    export default defineComponent({
        // eslint-disable-next-line vue/multi-word-component-names
        name: "TablePageThird",
        // eslint-disable-next-line vue/no-unused-components
        components: {Img, Navbar, Footer, Container, Breadcrumbs},
        mixins: [mixin],
        props: {
            id: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                publications: [],
                menu_child: [],
                static_info: new Map(),
                cur_lang: localStorage.getItem('lang')
            }
        },
        mounted() {
        },
        created() {
            axios.get(apiUrls.PUBLICATIONS + "?" + this.setParams({'language': localStorage.getItem('lang')}))
                .then(response => {
                    this.publications = response.data.data;
                }).catch(error => {
                console.log(error);
            }).finally(() => {
            });

            axios.get(apiUrls.SYSTEM_SETTINGS + "?" + this.setParams({'language': localStorage.getItem('lang')}))
                .then(response => {
                    for (let i = 0; i < response.data.total; i++) {
                        this.static_info.set(response.data.data[i].name, response.data.data[i].value)
                    }
                }).catch(error => {
                console.log(error);
            }).finally(() => {
            });

            axios.get(apiUrls.SITE_MENUS + "?" + this.setParams({
                'language': localStorage.getItem('lang'),
                'id': this.id
            }))
                .then(response => {
                    this.menu_child = response.data.data;
                }).catch(error => {
                console.log(error)
            }).finally(() => {
            });
        },
    })
</script>

<style scoped>
    table {
        border-collapse: collapse;
    }

    .dd-table__main-item_number {
        position: relative;
        padding: 2px 12px;
        font-size: 1.25rem;
    }

    .dd-table__main-item .lx_act_state {
        position: relative;
        left: 4px;
        font-size: 32px;
        top: -8px;
    }

    .dd-table__main-item .lx_act_state .status_code_y {
        color: #5FA8FF;
    }

    .lx_link:hover {
        text-decoration: none;
        color: #0064a9 !important;
    }

    .dd-table__main-left-desc {
        font-size: 1.125rem;
        position: relative;
        padding-left: 50px;
        width: 100%;
        margin-top: -48px;
    }

    .lx_link {
        cursor: pointer;
        color: inherit !important;
    }

    .dd-table__main-left-desc {
        font-size: 1.125rem;
    }

    .dd-table__main-item .badge-nine {
        font-size: .8rem;
        font-weight: 400;
        padding: 7px 21px;
        margin-top: 8px;
        margin-left: 10px;
        background-color: #ececec;
    }

    .dd-table__main-item .badge-pill {
        white-space: unset;
        margin-right: 10px;
    }

    .badge-pill {
        border-radius: 10rem;
    }

    .badge {
        display: inline-block;
        line-height: 1;
        text-align: center;
        vertical-align: baseline;
    }

    .dd-table__main-item {
        padding: 15px 0;
        display: flex;
        border-top: 1px solid #e2e2e2;
        border-bottom: 1px solid #e2e2e2;
    }

    .heading-title span {
        font-weight: 900;
    }

</style>
