<template>
    <div class="bg-gray-300/10 py-10">
        <div class="uk-container ">
            <div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slider autoplay>
                <ul class="uk-slider-items uk-child-width-1-2 uk-child-width-1-4@m uk-child-width-1-2@s uk-grid">
                    <li v-for="slide in slider" :key="slide" class="shadow-xl border bg-white mx-10">
                        <a class="uk-flex uk-flex-center uk-text-decoration-none md:py-5 py-1" :href="slide.link">
                            <img :src="slide.img" width="50" height="40" alt="logo">
                            <div class="uk-text-middle md:text-[20px] text-[16px]">
                                <p class="uk-text-secondary uk-text-center uk-text-break px-4 uk-text-decoration-none">{{slide.title}}</p>
                            </div>
                        </a>
                    </li>
                </ul>
                <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>
            </div>
        </div>
    </div>
</template>

<script>
    import gerb from "../../assets/gerb.svg"
    import lexuz from "../../assets/lexuz.svg"
    import mygov from "../../assets/logomygov.png"
    import strategiya from "../../assets/strategtiya.png"
    export default {
        name: "BottomSlider",
        data: () => ({
            slider: [
            {
                title: "O‘zbekiston Respublikasi Oliy Majlisining Senati",
                link: "https://senat.uz/uz",
                img: gerb,
            },
            {
                title: "O‘zbekiston Respublikasi Hukumati portali",
                link: "https://gov.uz/uz",
                img: gerb,
            },
            {
                title: "O‘zbekiston Respublikasi Qonunchilik ma’lumotlari milliy bazasi",
                link: "https://lex.uz/uz/",
                img: lexuz,
            },
            {
                title: "O‘zbekiston Respublikasi Prezidentining virtual qabulxonasi",
                link: "https://pm.gov.uz/oz#/",
                img: gerb,
            },
            {
                title: "Yagona interaktiv davlat xizmatlari portali",
                link: "https://my.gov.uz/oz",
                img: mygov,
            },
            {
                title: "O‘zbekiston Respublikasi harakatlar strategiyasi",
                link: "https://strategy.uz/index.php?news=1382&lang=uz",
                img: strategiya,
            },
        ]
        })

    }
</script>

<style scoped>

</style>