<template>
    <div class="w-[280px] h-auto mx-auto">
        <div>
            <div class="border bg-white border-[#c3c3c3] flex flex-col rounded-[15px] hover:cursor-pointer"
                style="box-shadow: 0px 10px 30px rgb(209 213 223 / 50%);" >

                <div class="w-full border-[#8060E8] xl:block hidden"  clickable  uk-toggle="target: #modal-example">
                    <div class="max-w-[1364px] mx-auto">
                        <div class="flex items-center p-[20px]">
                            <div class="flex-row ">
                                <!-- <div class="mr-[11px]">
                                    <img class="hover:cursor-pointer" src="@/assets/star.png" alt="star" style="width: 24px;">
                                </div> -->
                            </div>
                            <div class="ml-auto flex flex-row" clickable  uk-toggle="target: #modal-example">
                                <img class="hover:cursor-pointer" src="@/assets/dots.png" alt="dots" style="width: 24px;">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- modal -->
                <div id="modal-example" uk-modal>
                    <div class="uk-modal-dialog uk-modal-body bg-white pb-16 border-l-4 border-[#614CA7]">
                        <p class="uk-text-right uk-position-top-right w-[70px] h-[65px] bg-inherit">
                            <button class="uk-button uk-button-default w-full h-full uk-modal-close bg-inherit font-bold text-[40px] text-[#614CA7] border-0" type="button">&times;</button>
                        </p>
                        <slot name="modal_title"></slot>
                        <slot name="masshtab"></slot>
                        <slot name="nomenklatura"></slot>
                        <slot name="format_type"></slot>
                        <slot name="titul"></slot>
                        <slot name="object_name"></slot>
                        <slot name="regions_names"></slot>
                        <slot name="income_to_dgkf"></slot>
                        <slot name="year_of_material"></slot>
                        <slot name="maker_organizations"></slot>
                    </div>
                </div>
                <!-- /modal -->


                <div class="flex mx-[10px] rounded-[15px] relative items-center justify-center"  clickable  uk-toggle="target: #modal-example">
                    <div class="absolute top-[-2.5px] right-[-16px] rounded-[7px]" v-if="onSale"
                         :style="'background-color: ' + saleColor"
                    >
                        <div class="text-[13px] text-[#fff] font-medium pt-[4px] pb-[6px] px-[7px]">
                            <slot name="sale_title"></slot>
                        </div>
                    </div>
                    <slot name="img"></slot>
                </div>
                <div class="font-semibold mb-[18px]"
                     style="text-align: center"  clickable  uk-toggle="target: #modal-example">
                    <slot name="title"></slot>
                </div>
<!--                <div class="mx-[26px] flex flex-col">-->
<!--                    <div class="mb-[18px] text-[14px] font-medium  leading-[18px] card-text" v-if="hasDeliver">-->
<!--                        <slot name="deliver"></slot>-->
<!--                    </div>-->
<!--                    <div class="flex flex-row justify-between" v-if="onSale">-->
<!--                        <div class="card-sale-price">-->
<!--                            <slot name="sale_price"></slot>-->
<!--                        </div>-->
<!--                        <div class="card-price">-->
<!--                            <del>-->
<!--                                <slot name="original_price"></slot>-->
<!--                            </del>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="flex flex-row">-->
<!--                        <div class="card-sale-price">-->
<!--                            <slot name="original_price"></slot>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->


                <div class="pl-[20px] pr-[20px]"  clickable  uk-toggle="target: #modal-example">
                    <hr/>
                </div>

                <div class="w-full border-[#8060E8] xl:block hidden">
                    <div class="max-w-[1364px] mx-auto">
                        <div class="flex items-center p-[20px]">
                            <div class="flex-row ">
                                <p>{{__("file_size")}}:</p>
                                <p>--</p>
                            </div>
                            <div class="ml-auto flex flex-row">
                                <img @click="addBasket(item)" class="hover:cursor-pointer" src="@/assets/add_to_basket.png" alt="basket" style="width: 32px;" />
                            </div>
                            <vue-basic-alert :duration="500" :closeIn="1500" ref="alert" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<!--@click="addBasket(source)"-->
<script>
    import {mapMutations} from "vuex";
    import { mapGetters } from 'vuex'
    import VueBasicAlert from 'vue-basic-alert';

    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: "Card",
        components:{
            VueBasicAlert
        },
        props: {
            onSale: Boolean,
            hasDeliver: Boolean,
            fav: Boolean,
            white: Boolean,
            blue: Boolean,
            _saleColor: String,
            item: Object
        },
        data() {
            return {
                saleColor: this._saleColor ?? '#14BF30'
            }
        },

        methods: {
            ...mapMutations([
                'addStore',
            ]),
            ...mapGetters([
                'getSIMetaData'
            ]),

            addBasket(item) {
                this.addStore(item);
                this.$refs.alert.showAlert(
                        'success',
                        '',
                        'Fayl savatchaga qo`shildi.',
                        'Success 200',
                        'Form successfully submitted.'
                    )
                // if (!(this.getSIMetaData()?.includes(item))) {
                //     this.$refs.alert.showAlert(
                //         'success',
                //         'Success',
                //         'Form successfully submitted.',
                //         'Success 200',
                //         'Form successfully submitted.'
                //     )
                // } else if (this.getSIMetaData()?.includes(item)) {
                //     this.$refs.alert.showAlert(
                //         'error',
                //         'Failded',
                //         'Form failed validation.',
                //         'Failded 404',
                //         'Form failed validation.'
                //     )
                // }
            },
        }
    }
</script>

<style scoped>
    .card-header {
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;

    }
</style>