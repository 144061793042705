<template>
    <div>
        <Navbar :publications="publications"/>
        <order-confirmation />
        <Footer :static_info="static_info" :cur_lang="cur_lang"/>
    </div>
</template>

<script>
    import Navbar from "@/components/Layouts/Navbar";
    import Footer from "@/components/Layouts/Footer";
    import OrderConfirmation from "@/components/Layouts/OrderConfirmation";
    import mixin from "@/boot/boot-mixin";
    import axios from "axios";
    import {apiUrls} from "@/utils/const/apiUrls";
    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: "Checkout",
        components: {OrderConfirmation, Footer, Navbar},
        mixins: [mixin],
        data() {
            return {
                publications: [],
                system_settings: [],
                static_info: new Map(),
                cur_lang: localStorage.getItem('lang')
            }
        },
        created() {
            axios.get(apiUrls.PUBLICATIONS + "?" + this.setParams({'language': localStorage.getItem('lang')}))
                .then(response => {
                    this.publications = response.data.data;
                }).catch(error => {
                console.log(error);
                console.log(error)
            }).finally(() => {
            });
            axios.get(apiUrls.SYSTEM_SETTINGS + "?" + this.setParams({'language': localStorage.getItem('lang')}))
                .then(response => {
                    this.system_settings = response.data.data;
                    for (let i = 0; i < response.data.total; i++) {
                        this.static_info.set(response.data.data[i].name, response.data.data[i].value)
                    }
                }).catch(error => {
                console.log(error);
                console.log(error)
            }).finally(() => {
            });
        },
    }
</script>

<style scoped>

</style>