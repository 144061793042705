<template>
    <Vue3Marquee style="margin-top:2px;font-size: 18px;font-weight: bolder; color: #614CA7" scrollamount="7">
        {{__('site_is_test_mode')}} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; • &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {{__('site_is_test_mode')}} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; • &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {{__('site_is_test_mode')}} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; • &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {{__('site_is_test_mode')}}
    </Vue3Marquee>
    <div>
        <div class="relative w-full bg-[#F5F7FB] header-top h-70 z-10">
            <div class="uk-container items-center h-full uk-flex uk-child-width-1-2@m">
                <router-link to="/" class="responsive-logo flex items-center hover:no-underline"
                             uk-scrollspy="cls: uk-animation-slide-left-medium; delay:300; repeat: true">
                    <img alt="logo" src="../../assets/gerb.svg" uk-img>
                    <div class="uppercase ml-4 text-[#614CA7] text-[20px] font-extrabold hover:no-underline">
                        <div class="flex-wrap uk-text-bold logo-margin text-[#000000] nav-text text-[18px] font-bold leading-[22px]">
                            DAVLAT KARTOGRAFIYA-GEODEZIYA FONDINING <span class="text-[#614CA7] text-[18px] font-bold">MA'LUMOTLAR PORTALI</span>
                        </div>
                    </div>
                </router-link>
                <div class="md:flex justify-end uk-visible@m text-[#fff] hidden"
                     uk-scrollspy="cls: uk-animation-slide-right-medium; delay:300; repeat: true">
                    <ul class="uk-grid uk-grid-small items-center shrink-0 lg:text-[15px] md:text-[14px] shrink-link space-x-6">
                        <li class="pr-4 header-top-link">
                            <a href="tel:998712467732"
                               class="flex items-center text-[#fff] no-underline hover:text-[#fff] transition duration-150"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16.869" height="16.869"
                                     viewBox="0 0 16.869 16.869" class="mr-4">
                                    <path id="Path_484" data-name="Path 484"
                                          d="M15.515,11.072a9.577,9.577,0,0,1-3.008-.479,1.375,1.375,0,0,0-1.337.282l-1.9,1.432A10.482,10.482,0,0,1,4.561,7.6l1.39-1.847a1.363,1.363,0,0,0,.335-1.381,9.592,9.592,0,0,1-.481-3.013A1.355,1.355,0,0,0,4.451,0h-3.1A1.355,1.355,0,0,0,0,1.354,15.533,15.533,0,0,0,15.515,16.869a1.355,1.355,0,0,0,1.354-1.354V12.426A1.355,1.355,0,0,0,15.515,11.072Z"
                                          fill="#fff"/>
                                </svg>
                                <span class="font-medium">
                                    (+998 71) 246-77-32
                                </span>
                            </a>
                        </li>
                        <li class="header-top-link">
                                <router-link :to="'/cabinet'" v-if="storeUser.id"
                                         class="flex flex-col td justify-center cabinet">
                                <div class="mx-auto">
                                    <img class="w-[22px] h-[24px]" src="@/assets/kabinet-icon.svg" alt="icon" width="23" height="25">
                                </div>
                                <div class="navbar-item mt-[4.4px]">
<!--                                    {{ __('kabinet') }}-->
                                </div>
                            </router-link>
                            <a v-else :href="oneIDUri"
                               class="text-[#fff] hover:text-[#fff] transition duration-150 font-medium"
                            >
                                {{__("one_id")}}
                            </a>
                        </li>
                        <li class="header-top-link">
                            <a href="javascript:void(0);" class="flex flex-col td justify-center relative" @click.stop="reloadStoreItem"
                                uk-toggle="target: #offcanvas-flip">
                                <div class="mx-auto">
                                    <img src="@/assets/korzinka-icon.svg" alt="">
                                </div>
                                <div
                                        class="absolute top-[-10px] left-[20px] text-[#fff] text-[15px] bg-[#14BF30] px-[7px]  rounded-[50%]"
                                        v-if="this.$store.getters.getSIMetaData.length > 0">
                                    {{ this.$store.getters.getSIMetaData.length }}
                                </div>
                            </a>
                        </li>
                        <li class="header-top-link">
                            <a href="javascript:void(0)"
                                class="w-[37px] h-[37px] flex items-center justify-center text-[#fff] text-[16px] font-bold hover:no-underline ">
                                <span
                                        class="w-[37px] h-[37px] flex items-center justify-center text-[#fff] text-[16px] font-medium">
                                    {{ currentLang === 'ru' ? 'Ru' : '' }}
                                    {{ currentLang === 'uz' ? 'Uz' : '' }}
                                    {{ currentLang === 'en' ? 'En' : '' }}
                                </span>
                                <div uk-dropdown="mode: click;pos: bottom-right"
                                    class="rounded-[5px] bg-[#fff] uk-box-shadow-medium dark-lang"
                                    style="padding: 5px!important; min-width: 47px!important;">
                                    <ul class=" uk-navbar-dropdown-nav text-[#000]">
                                        <li
                                        v-for="(item,index) in languages.filter(item => item !== currentLang)"
                                            :key="index"
                                            class="first:mt-0 mt-2 px-6 pt-1 border-b">
                                            <a href="javascript:void(0)"
                                                class="hover:no-underline navbar-lang dark-lang text-[18px] font-medium "
                                                @click="setLang(item)"
                                            >
                                                {{ item === 'ru' ? 'Русский' : '' }}
                                                {{ item === 'uz' ? 'O`zbek' : '' }}
                                                {{ item === 'en' ? 'English' : '' }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                <nav class="uk-navbar offcanvas-navbar">
                    <div class="uk-navbar-right">
                        <a href="#" class="uk-navbar-toggle uk-hidden@m" uk-navbar-toggle-icon
                           uk-toggle="target: #sidenav"></a>
                    </div>
                </nav>
            </div>
        </div>
        <header class="header-sticky"
                uk-sticky="top: 100; animation: uk-animation-slide-top;cls-active: bg-[#fff] z-[999]">
            <div class="uk-container">
                <nav class="uk-navbar">
                    <div class="uk-navbar-center">
                        <ul class="uk-navbar-nav uk-visible@m">
                            <li class="uk-active text-nowrap header-top-link" v-for="menu in menus" :key="menu.id">
                                <router-link v-if="!menu.children && menu.is_onclick==0" :to="menu.router_link">
                                    {{menu.name}}
                                </router-link>
                                <router-link v-else-if="menu.is_onclick==1 && menu.router_link.includes('news')" to="" @click.stop="toNewsPage(publications[0])">
                                    {{menu.name}}
                                </router-link>
                                <router-link v-else-if="menu.is_onclick==1 && menu.router_link.includes('documents')" to="" @click.stop="toDocs(menu)">
                                    {{menu.name}}
                                </router-link>
                                <a v-else href="javascript:void(0)">
                                        {{ menu.name }}
                                </a>
                                <div v-if="menu.children"  uk-dropdown="mode: click;pos: bottom-center"
                                        class="rounded-[5px] bg-[#fff] uk-box-shadow-medium dark-lang"
                                        style="margin-top: 0px; left: -20.4451px !important; top: 80px; width: 320px;">
                                        <ul class="uk-navbar-dropdown-nav space-y-3">
                                        <li class="after-none" v-for="child in menu.children" :key="child.id">
                                            <router-link v-if="child.is_onclick==1" class="hover:no-underline navbar-lang dark-lang" to="" @click.stop="toTablePage(child)">
                                                {{ child.name }}
                                            </router-link>

                                            <router-link v-else class="hover:no-underline navbar-lang dark-lang" :to="child.router_link">
                                                {{ child.name }}
                                            </router-link>
                                            <hr class="mx-3">
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
        <div id="sidenav" uk-offcanvas="flip: true" class="uk-offcanvas">
            <div class="uk-offcanvas-bar">
                <button class="uk-offcanvas-close" type="button" uk-close></button>
<!--                <ul class="">-->
<!--                    <li>-->
<!--                        <a href="javascript:void(0)" class="text-black">-->
<!--                            {{ __("about") }}-->
<!--                        </a>-->
<!--                    </li>-->
<!--                    <li class="uk-active">-->
<!--                        <a href="javascript:void(0)">-->
<!--                            {{__('news')}}-->
<!--                        </a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <a href="javascript:void(0)">-->
<!--                            {{__('service')}}-->
<!--                        </a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <a href="javascript:void(0)">-->
<!--                            {{__('freaquently')}}-->
<!--                        </a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <a href="javascript:void(0)">-->
<!--                            {{__('legal_document')}}-->
<!--                        </a>-->
<!--                    </li>-->
<!--                </ul>-->
              <ul class="">
                <li class="uk-active text-nowrap header-top-link" v-for="menu in menus" :key="menu.id">
                  <router-link v-if="!menu.children && menu.is_onclick==0" :to="menu.router_link">
                    {{menu.name}}
                  </router-link>
                  <router-link v-else-if="menu.is_onclick==1 && menu.router_link.includes('news')" to="" @click.stop="toNewsPage(publications[0])">
                    {{menu.name}}
                  </router-link>
                  <router-link v-else-if="menu.is_onclick==1 && menu.router_link.includes('documents')" to="" @click.stop="toDocs(menu)">
                    {{menu.name}}
                  </router-link>
                  <a v-else href="javascript:void(0)">
                    {{ menu.name }}
                  </a>
                  <div v-if="menu.children"  uk-dropdown="mode: click;pos: bottom-center"
                       class="rounded-[5px] bg-[#fff] uk-box-shadow-medium dark-lang"
                       style="margin-top: 0px; left: -20.4451px !important; top: 80px; width: 20px;">
                    <ul class="uk-navbar-dropdown-nav space-y-3">
                      <li class="after-none" v-for="child in menu.children" :key="child.id">
                        <router-link v-if="child.is_onclick==1" class="hover:no-underline navbar-lang dark-lang" to="" @click.stop="toTablePage(child)">
                          {{ child.name }}
                        </router-link>

                        <router-link v-else class="hover:no-underline navbar-lang dark-lang" :to="child.router_link">
                          {{ child.name }}
                        </router-link>
                        <hr class="mx-3">
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>

                <ul class="items-center py-auto">
                    <li class="pr-4 header-top-link my-4">
                        <a href="tel:998712020123"
                           class="flex items-center text-[#fff] no-underline hover:text-[#fff] transition duration-150"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16.869" height="16.869"
                                 viewBox="0 0 16.869 16.869" class="mr-4">
                                <path id="Path_484" data-name="Path 484"
                                      d="M15.515,11.072a9.577,9.577,0,0,1-3.008-.479,1.375,1.375,0,0,0-1.337.282l-1.9,1.432A10.482,10.482,0,0,1,4.561,7.6l1.39-1.847a1.363,1.363,0,0,0,.335-1.381,9.592,9.592,0,0,1-.481-3.013A1.355,1.355,0,0,0,4.451,0h-3.1A1.355,1.355,0,0,0,0,1.354,15.533,15.533,0,0,0,15.515,16.869a1.355,1.355,0,0,0,1.354-1.354V12.426A1.355,1.355,0,0,0,15.515,11.072Z"
                                      fill="#fff"/>
                            </svg>
                            <span class="font-medium">
                            (+998 71) 246-77-32
                        </span>
                        </a>
                    </li>
                  <li class="">
                    <router-link :to="'/cabinet'" v-if="storeUser.id"
                                 class="flex flex-col td justify-center cabinet">
                      <div class="mx-auto">
                        <img class="w-[22px] h-[24px]" src="@/assets/kabinet-icon.svg" alt="icon" width="23" height="25">
                      </div>
                      <div class="navbar-item mt-[4.4px]">
                        <!--                                    {{ __('kabinet') }}-->
                      </div>
                    </router-link>
                    <a v-else :href="oneIDUri"
                       class="text-[#fff] hover:text-[#fff] transition duration-150 font-medium"
                    >
                      {{__("one_id")}}
                    </a>
                  </li>
                  <li class="">
                    <a href="javascript:void(0)"
                       class="w-[37px] h-[37px] flex items-center justify-center text-[#fff] text-[16px] font-bold hover:no-underline ">
                                <span
                                    class="w-[37px] h-[37px] flex items-center justify-center text-[#fff] text-[16px] font-medium">
                                    {{ currentLang === 'ru' ? 'Ru' : '' }}
                                    {{ currentLang === 'uz' ? 'Uz' : '' }}
                                    {{ currentLang === 'en' ? 'En' : '' }}
                                </span>
                      <div uk-dropdown="mode: click;pos: bottom-right"
                           class="rounded-[5px] bg-[#fff] uk-box-shadow-medium dark-lang"
                           style="padding: 5px!important; min-width: 47px!important;">
                        <ul class=" uk-navbar-dropdown-nav text-[#000]">
                          <li
                              v-for="(item,index) in languages.filter(item => item !== currentLang)"
                              :key="index"
                              class="first:mt-0 mt-2 px-6 pt-1 border-b">
                            <a href="javascript:void(0)"
                               class="hover:no-underline navbar-lang dark-lang text-[18px] font-medium "
                               @click="setLang(item)"
                            >
                              {{ item === 'ru' ? 'Русский' : '' }}
                              {{ item === 'uz' ? 'O`zbek' : '' }}
                              {{ item === 'en' ? 'English' : '' }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </a>
                  </li>
                </ul>
            </div>
        </div>
    </div>

    <div id="offcanvas-flip" uk-offcanvas="flip: true; overlay: true"
         class="uk-overlay-primary h-full">
        <div class="uk-offcanvas-bar"
             style="padding: 0px!important; width: 504px!important;height: 100%!important;">
<!--            <Karzinka @checkOrderConfirm="checkOrder" :show="test"/>-->
            <Basket />
        </div>
    </div>

</template>

<script>
    import { Vue3Marquee } from 'vue3-marquee'
    import 'vue3-marquee/dist/style.css'
    import Basket from "@/components/Block/Basket";
    import axios from "axios";
    import { defineComponent } from "vue";
    import {apiUrls} from "@/utils/const/apiUrls";
    export default defineComponent({
        // eslint-disable-next-line vue/multi-word-component-names
        name: "Navbar",
        components: {Basket, Vue3Marquee},
        props: {
            publications: Array,
        },
        data() {
            return {
                languages: ['uz', 'ru', 'en'],
                currentLang: localStorage.getItem('lang'),
                menus:[],
                clientId: 'online-auksion_uz',
                clientSecret: 'Mpy4Zx84UCPdORSTNcnW89sO',
                state: 'Mpy4Zx84UCPdORSTNcnW89sO',
                oneIdUri: 'https://sso.egov.uz/sso/oauth/Authorization.do',
                code: window.location.search ? window.location.search.replace('?code=', '') : '',
                redirect_uri: location.origin.includes('localhost') ? 'http://localhost:8080/' : 'https://geofond.kadastr.uz/',
            }
        },
        computed: {
            oneIDUri() {
                return this.oneIdUri + '?response_type=one_code&client_id=' + this.clientId
                    + '&redirect_uri=' + this.redirect_uri + '&scope=' + this.clientId + '&state=' + this.state
            },
            storeUser() {
                return this.$store.getters.getUser;
            }
        },
        // created() {
        //     axios.get(apiUrls.SITE_MENUS)
        //         .then(res => {
        //             this.menus = res.data.data;
        //             this.spinner = false
        //             console.log(this.menus);
        //         })
        //         .catch(function (error) {
        //             console.log(apiUrls.STAFFS);
        //             console.log(error);
        //         })
        //         .finally(() => this.spinner = false)
        // },
        methods: {
            toNewsPage(row) {
                this.$router.push({
                    name: 'news',
                    params: {id: row.id}
                })
                // console.log(row.id);
            },
            toTablePage(row) {
                this.$router.push({
                    path: row.router_link + '/' + row.id,
                    params: {id: row.id}
                })
            },
            toDocs(row) {
                this.$router.push({
                    path: row.router_link + '/' + row.id,
                    params: {id: row.id}
                })
            },
            setLang(value) {
                localStorage.setItem('lang', value);
                this.currentLang = localStorage.getItem('lang');
                this.$emit("changeLanguage", this.currentLang)
                location.reload()
            },

            reloadStoreItem() {
            },

            // toCabinet() {
            //     this.$router.push("/cabinet")
            // },

            toCabinet() {
                let href = window.location.href.split('?code=');
                // href = href.split('?code=');
                console.log(href);
                window.location.href = href[0] + '#/cabinet';
            },

            // getToken() {
            //     axios.post(this.oneIdUri + '?grant_type=one_authorization_code&' +
            //         'client_id=' + this.clientId + '&client_secret=' + this.clientSecret + '&code=' + this.code +
            //         '&redirect_uri=' + this.redirect_uri)
            //         .then(res => {
            //             this.getData(res.data.access_token)
            //         })
            // },
            // getData(token) {
            //     axios.post(this.oneIdUri + '?grant_type=one_access_token_identify&' +
            //         'client_id=' + this.clientId + '&client_secret=' + this.clientSecret +
            //         '&access_token=' + token + '&scope=' + this.code)
            //         .then(res => {
            //             this.oauth(this.code)
            //         })
            // },
            oauth(data) {
                axios.post(apiUrls.ONE_ID_OAUTH2, data).then(res => {
                    this.$store.dispatch("login", res.data).then(() => {
                            this.toCabinet()
                        }
                    )

                })
            },

            getMenus() {
            let filter = {}
            axios.get(apiUrls.SITE_MENUS, filter)
                .then(res => {
                    this.menus = res.data.data;
                    this.spinner = false
                    // console.log(this.menus);
                })
                .catch(function (error) {
                    // console.log(apiUrls.STAFFS);
                    console.log(error);
                })
                .finally(() => this.spinner = false)
        },
        },
        mounted() {
            this.getMenus()
            let user = this.storeUser

            if (this.code && !user.id) {
                console.log(this.code);
                this.oauth(this.code.split('&state')[0])
            }
        }
    })
</script>

<style scoped>
    header {
        border-bottom: 1px solid #DEDEDE;
    }

    .header-sticky {
        background: #ffffff !important;
    }

    @media (min-width: 960px) and (max-width: 1200px) {
        .shrink-link {
            font-size: 12px;
        }
    }

    @media (min-width: 960px) {
        .header-top::after {
            content: "";
            position: absolute;
            right: 0;
            width: 50%;
            top: 0;
            height: 100%;
            display: block;
            background-color: #614CA7;
            z-index: -1;
        }

        .offcanvas-navbar {
            display: none;
        }
    }

    @media (min-width: 960px) {
        .header-top-left::after {
            content: "";
            position: absolute;
            left: 0;
            width: 50%;
            top: 0;
            height: 100%;
            display: block;
            background-color: #F5F7FB;
            z-index: -1;
        }

        .offcanvas-navbar {
            display: none;
        }
    }

    @media (min-width: 425px) and (max-width: 767px) {
        .header-top::after {
            content: "";
            position: absolute;
            right: 0;
            width: 50%;
            top: 0;
            height: 100%;
            display: block;
            z-index: -1;
        }

        .responsive-logo {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    @media (min-width: 320px) and (max-width: 424px) {
        .responsive-logo {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }


    .uk-navbar-nav li a {
        margin-right: 1.5em;
        margin-left: 1.5em;
        position: relative;
        font-weight: 500 !important;
        font-size: 16px;
        color: #393939;
        transition: all .2s ease;
        text-transform: none !important;
    }

    .uk-navbar-nav li a:hover {
        color: #614CA7;
        font: bold;
    }

    /* .uk-navbar-nav li.uk-active a {
        color: #FE634E;
    }

    .uk-navbar-nav li.uk-active a::after {
        content: '';
        bottom: 0;
        left: 0;
        background-color: #FE634E;
        width: 100%;
        height: 4px;
        display: block;
        position: absolute;
    } */

    .uk-navbar-nav li a::before {
        content: '';
        position: absolute;
        height: 19px;
        width: 1px;
        right: -22px;
        top: 50%;
        transform: translateY(-50%);
        background-color: #D5D5D0;
    }

    .uk-navbar-nav .after-none a::before {
        display: none;
    }

    .uk-navbar-nav li:last-child a::before {
        display: none;
    }

    .header-top-link {
        position: relative;
    }

    .header-top-link::after {
        content: '';
        position: absolute;
        right: -10px;
        top: 50%;
        transform: translateY(-50%);
        height: 15px;
        width: 2px;
        border-radius: 99999999px;
        display: block;
        background-color: #fff;
    }

    .header-top-link:last-child:after {
        display: none;
    }

    .uk-offcanvas-bar {
        background: #614CA7;
    }
    .logo-margin{
      margin-right: 9rem;
    }

    @media (min-width: 1024px){
      .h-70{
        height: 70px;
      }
    }
    @media (min-width: 600px) and (max-width: 1023px) {
        .header-top-link::after {
            display: none;
        }
      .h-70{
        height: 70px;
      }

        li {
            padding: 5px 0px;
        }
        .logo-margin{
          margin-right: 0rem;
        }
    }
    @media (min-width: 320px) and (max-width: 599px) {
        .header-top-link::after {
            display: none;
        }

        li {
            padding: 5px 0px;
        }

        .nav-text{
          font-size: 15px;
        }

        .logo-margin{
          margin-right: 0rem;
        }
    }




</style>
