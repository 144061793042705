<template>
    <div class="card-statistics-container">
        <div class="flex space-x-6">
            <div>
                <div class="w-[92px] h-[92px] self-center">
                    <slot name="icon"></slot>
                </div>
            </div>
            <div class="pr-16 self-center">
                <div>
                    <div class="text-[#FFFFFF] text-[24px] md:text-[35px] font-semibold">
                        <slot name="number"></slot>
                    </div>
                </div>
                <div>
                    <div class="text-[#FFFFFF] text-start md:text-[16px] leading-[20px]">
                        <slot name="content"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "StatisticsCard"
}
</script>

<style scoped>
.card-statistics-container{
    position: relative;
}
.card-statistics-container::after {
    content: '';
    position: absolute;
    height: 35px;
    width: 2px;
    border-radius: 9px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #FFFFFF;
    display: block;
    right: 26px;
}
.card-statistics-container:last-child::after{
    display: none;
}
@media (max-width: 1200px) {
    .card-statistics-container::after{
        display: none;
    }
}
</style>