<template>
  <footer class="uk-section uk-padding-remove overflow-hidden bg-[#F5F7FB]"
          uk-scrollspy="cls: uk-animation-fade; delay: 200; repeat: true">
    <div class="p-0 pt-12 pb-6">
      <div class="uk-container  footer">
        <div class="width-55">
          <div class="flex mb-10">
            <div class="log_style border-1 self-top">
              <img class="w-[45px] h-[47px]" src="../../assets/gerb.svg" alt="">
            </div>
            <div
                class="log_style  uk-text-bold ml-4 mr-36 text-[#000000] text-[20px] font-bold ">
              DAVLAT KARTOGRAFIYA-GEODEZIYA FONDINING <span class="text-[#614CA7] text-[20px] font-bold">MA'LUMOTLAR PORTALI</span>
            </div>
          </div>
          <ul class="text-lg list-none pt-12 ml-2">
            <li v-if="cur_lang == 'uz'" class="text-lg text-[#2947A9] font-medium mb-3">{{ __("manzil") }}: <span
                class=" text-neutral-800" v-html="static_info.get('address_uz')"></span></li>
            <li v-else-if="cur_lang == 'ru'" class="text-lg text-[#2947A9] font-medium mb-3">{{ __("manzil") }}: <span
                class=" text-neutral-800" v-html="static_info.get('address_ru')"></span></li>
            <li v-else class="text-lg text-[#2947A9] font-medium mb-3">{{ __("manzil") }}: <span
                class=" text-neutral-800" v-html="static_info.get('address_en')"></span></li>
            <li class="text-lg text-[#2947A9] font-medium mb-3">{{ __("phone") }}: <a class="text-lg text-neutral-800"
                                                                                      href="tel:+99871 246-55-06"><span
                v-html="static_info.get('phone')"></span></a></li>
            <li class=" text-[#2947A9] font-medium mb-3">Faks: <a class="text-lg text-neutral-800"
                                                                         href="tel:+99871 246-77-32"><span
                v-html="static_info.get('fax')"></span></a></li>
            <li class=" text-[#2947A9] font-medium mb-3">E-mail: <a class="text-lg text-neutral-800"
                                                                           href="mailto:kartfond@umail.uz"
                                                                           v-html="static_info.get('email')"></a></li>
          </ul>
        </div>
        <div class="width-45">
          <div class="fs-16 bg-white text-black p-[10px] ">
            <a :href="'https://www.google.com/maps/search/?api=1&query=' + center[1] + ',' + center[0]"
               :title="mapAddress" target="_blank">
              {{ mapAddress }}
            </a>

          </div>
          <ol-map :loadTilesWhileAnimating="true" :loadTilesWhileInteracting="true"
                  style=" border: 2px solid #FFFFFF; border-radius: 25px;" class="h-320" ref="map">
            <ol-view ref="view" :center="center" :rotation="rotation" :zoom="zoom" :projection="projection"/>


            <ol-tile-layer>
              <ol-source-osm/>
            </ol-tile-layer>

            <ol-vector-layer :zIndex="2">
              <ol-source-vector>
                <ol-feature ref="positionFeature">
                  <ol-geom-point :coordinates="center"></ol-geom-point>
                  <ol-style>
                    <ol-style-icon :src="hereIcon" :scale="0.1"></ol-style-icon>
                  </ol-style>
                </ol-feature>
              </ol-source-vector>
            </ol-vector-layer>
          </ol-map>
        </div>
      </div>
    </div>
    <div class="bg-[#614CA7]">
      <div class="uk-container w-full py-[20px] flex">
        <div class="w-[100%] text-[#FFFFFF] text-[16px] font-regular self-center uk-cardd">
          <Grid class="w-full flex items-center uk-container">
            <div class="width-55">
              <div class="font-medium">
                <div v-html="static_info.get('created_by')"></div>
              </div>
            </div>
            <div class="width-45 items-center ">
              <div class="font-medium ">
                {{ static_info.get('app_support') }} {{ __("all_rights_reserved") }}
              </div>
            </div>
          </Grid>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import {defineComponent} from "vue";
import {ref} from 'vue'
import Grid from "@/components/Block/Grid";
import hereIcon from '@/assets/here-icon.png'
import axios from "axios";


export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Footer",
  components: {Grid},
  props: {
    static_info: Map,
    cur_lang: String
  },
  methods: {
    getAddress() {
      axios.get("https://nominatim.openstreetmap.org/reverse?format=json&lat=" + this.center[1] + "&lon=" + this.center[0] + "&zoom=18&addressdetails=1").then(
          (response) => {
            console.log(response.data)
            this.mapAddress = response.data.display_name
          }
      )
    }
  },
  mounted() {
    this.getAddress()
  },
  setup() {
    const center = ref([69.192806, 41.338528])
    const projection = ref('EPSG:4326')
    const zoom = ref(16)
    const rotation = ref(0)

    const view = ref(null)
    const map = ref(null)
    let mapAddress = ref('');
    return {
      mapAddress,
      center,
      projection,
      zoom,
      rotation,
      hereIcon,
      view,
      map
    }
  },
})
</script>

<style scoped>
.footer {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-wrap: wrap;
}

.width-55 {
  width: 55%;
}

.width-45 {
  width: 45%;
}

.h-320 {
  height: 320px;
}

.fs-16 {
  font-size: 16px;
}

@media (min-width: 600px) and (max-width: 1023px) {

  .width-55 {
    width: 80%;
  }

  .width-45 {
    width: 80%;
  }
  .h-320 {
    height: 300px;
  }
  .fs-16 {
    font-size: 14px;
  }
}


@media (max-width: 599px) {
  .width-55 {
    width: 90%;
  }

  .width-45 {
    width: 90%;
  }
  .h-320 {
    height: 200px;
  }
  .fs-16 {
    font-size: 14px;
  }
  .mr-36 {
    margin-right: 3px;
  }
}
</style>
